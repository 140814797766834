/* eslint-disable no-undef */
export function loadWhatConvertsScript(wcProfileId) {
  if (window && document?.documentElement?.firstChild) {
    const scriptPartOne = document.createElement('script');
    const scriptPartTwo = document.createElement('script');

    scriptPartOne.innerHTML = `var $wc_load=function(a){return JSON.parse(JSON.stringify(a))},$wc_leads=$wc_leads||{doc:{url:$wc_load(document.URL),ref:$wc_load(document.referrer),search:$wc_load(location.search),hash:$wc_load(location.hash)}};`;

    scriptPartTwo.type = 'text/javascript';
    scriptPartTwo.setAttribute('async', 'true');
    scriptPartTwo.setAttribute(
      'src',
      `//s.ksrndkehqnwntyxlhgto.com/${wcProfileId}.js`,
    );

    document.documentElement.firstChild.appendChild(scriptPartOne);
    document.documentElement.firstChild.appendChild(scriptPartTwo);
  }
}

export function collectWhatConvertsToken() {
  try {
    if (window.oqhaqzwnbjgcpycneryg) {
      const wcToken = window.oqhaqzwnbjgcpycneryg('wc_client');
      return wcToken;
    }
  } catch (e) {
    return null;
  }
}
