/* eslint-disable no-useless-escape */
export function validatePhoneNumber(phoneNumber: string) {
  // Regex to check valid
  // International Phone Numbers
  // eslint-disable-next-line prefer-regex-literals
  const regex = new RegExp(
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,20}$/im,
  );

  // if phoneNumber
  // is empty return false
  if (phoneNumber == null) {
    return false;
  }

  let stringToTest = '';
  for (let i = 0; i < phoneNumber.length; i++) {
    const charToTest = phoneNumber[i];
    if (!REMOVE_CHARS_FOR_PHONE.includes(charToTest)) {
      stringToTest += charToTest;
    }
  }

  // Return true if the phoneNumber
  // matched the ReGex
  if (regex.test(stringToTest)) {
    return true;
  } else {
    return false;
  }
}

export function validateEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function validatePassword(password: string): boolean {
  return !!password.length;
}

export function validateAddress(address: string): boolean {
  return !!address.length;
}

export function validateBusinessName(businessName: string): boolean {
  return !!businessName.length;
}

export function validateFirstName(firstName: string): boolean {
  return !!firstName.length;
}

export function validateLastName(lastName: string): boolean {
  return !!lastName.length;
}

export function checkIfAllowedPhoneNumberCharacter(char: string) {
  return [
    '+',
    '-',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    ' ',
  ].includes(char);
}

export function validateCity(city: string) {
  return !!city.length;
}

export function validateZip(zip: string) {
  return zip && zip.length === 5;
}

const REMOVE_CHARS_FOR_PHONE = ['-', ' '];
