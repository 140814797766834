import { SendRequestMessageData } from '../parentApp/types/messages';
import { APIBaseService } from './base.api';
import ParentAppService from '../parentApp';
import {
  ClientsReportCountryBody,
  ClientsReportUTMBody,
} from './types/clients.responses';

export class APIClientsService extends APIBaseService {
  static reportClientCountry(
    body: ClientsReportCountryBody,
    uuidKey?: string,
  ): string {
    const uuid = uuidKey || ParentAppService.generateUUID();
    const url = new URL(`${APIBaseService.apiUrl}/api/clients/report`).href;
    const data: SendRequestMessageData = {
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      url,
      uuid,
    };
    ParentAppService.sendRequestMessage(data);
    return uuid;
  }

  static reportClientUTM(body: ClientsReportUTMBody, uuidKey?: string): string {
    const uuid = uuidKey || ParentAppService.generateUUID();
    const url = new URL(`${APIBaseService.apiUrl}/api/clients/report-utm`).href;
    const data: SendRequestMessageData = {
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      url,
      uuid,
    };
    ParentAppService.sendRequestMessage(data);
    return uuid;
  }
}
