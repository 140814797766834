import { combineReducers } from '@reduxjs/toolkit';
import formReducer from './form.reducer';
import responsesReducer from './responses.reducer';
import { uiReducer } from './ui.reducer';

const rootReducer = combineReducers({
  form: formReducer,
  responses: responsesReducer,
  ui: uiReducer,
});

export default rootReducer;
