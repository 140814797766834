import React from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentFormStep,
  getFormStateSelector,
  getResponseStateSelector,
} from '../../store/selectors';
import './index.scss';

function WizardStepBar() {
  const barRef = React.useRef() as React.MutableRefObject<HTMLSpanElement>;
  const currentSteps = useSelector(getCurrentFormStep);
  const { steps } = useSelector(getFormStateSelector);
  const response = useSelector(getResponseStateSelector('submit-response'));

  React.useEffect(() => {
    if (response) {
      barRef.current.style.width = `100%`;
    } else if (steps?.length && typeof currentSteps === 'number') {
      const indexOfStep = steps.findIndex((step) => step.id === currentSteps);
      barRef.current.style.width = `${(
        (((indexOfStep !== -1 ? indexOfStep : 0) + 1) / (steps?.length + 1)) *
        100
      ).toFixed(2)}%`;
    }
  }, [steps, currentSteps, response]);

  return (
    <div className="wizard-step-bar-component-container">
      <span ref={barRef} className="wizard-step-bar-component-bar" />
    </div>
  );
}

export default WizardStepBar;
