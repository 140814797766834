import React from 'react';

export function useInputFocused(ref: React.MutableRefObject<HTMLInputElement>) {
  const [focused, setFocused] = React.useState(false);

  React.useEffect(() => {
    const onFocus = () => {
      setFocused(true);
    };
    const onBlur = () => {
      setFocused(false);
    };
    if (ref.current) {
      ref.current.addEventListener('focus', onFocus);
      ref.current.addEventListener('blur', onBlur);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('focus', onFocus);
        ref.current.removeEventListener('blur', onBlur);
      }
    };
  }, [ref.current]);

  return focused;
}
