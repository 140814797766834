import { ReduxActionPayload } from '../actions/types';
import { ActionTypes } from '../actions/types/type.actions.d';
import {
  OpenUiActionPayload,
  RemoveAnimatingComponentsActionPayload,
  UIState,
  UpdateAnimatingComponentsActionPayload,
} from '../actions/types/ui.actions.d';

const defaultState: UIState = {
  isOpen: false,
  isFixed: false,
  origin: null,
  width: null,
  finished: null,
  isFromLink: null,
  componentsAnimating: [],
};

export function uiReducer(state = defaultState, action: ReduxActionPayload) {
  switch (action.type) {
    case ActionTypes.OPEN: {
      const { isFromLink, isFixed } = action.payload as OpenUiActionPayload;
      return {
        ...state,
        isOpen: true,
        isFixed,
        isFromLink,
      };
    }
    case ActionTypes.CLOSE: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case ActionTypes.UPDATE_ANIMATING_COMPONENTS: {
      const payloadData =
        action.payload as UpdateAnimatingComponentsActionPayload;
      const newComponentsAnimating: string[] = [...state.componentsAnimating];
      payloadData.components.forEach((cp) => {
        if (!newComponentsAnimating.includes(cp)) {
          newComponentsAnimating.push(cp);
        }
      });
      return {
        ...state,
        componentsAnimating: [...newComponentsAnimating],
      };
    }
    case ActionTypes.REMOVE_ANIMATING_COMPONENTS: {
      const payloadData =
        action.payload as RemoveAnimatingComponentsActionPayload;
      const newComponentsAnimating: string[] = [];
      state.componentsAnimating.forEach((cp) => {
        if (!payloadData.components.includes(cp)) {
          newComponentsAnimating.push(cp);
        }
      });
      return {
        ...state,
        componentsAnimating: [...newComponentsAnimating],
      };
    }
    default:
      return state;
  }
}
