export function loadGA4Script(GA4_ID) {
  if (window && document?.documentElement?.firstChild) {
    const newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    newScript.setAttribute('async', 'true');
    newScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${GA4_ID}`,
    );
    document.documentElement.firstChild.appendChild(newScript);
    newScript.onload = () => {
      const loadCheck = () => {
        if (!window.gtag) {
          window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag() {
            window.dataLayer.push(arguments);
          };
        }
        window.gtag('js', new Date());
        window.gtag('config', GA4_ID, {
          send_page_view: false,
        });
        window.gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
        });
      };
      setTimeout(() => {
        loadCheck();
      }, 500);
    };
  }
}

export function sendGA4LeadEvent(email, GA4_ID) {
  if (window.gtag) {
    window.gtag('event', 'generate_lead', {
      email,
      send_to: GA4_ID,
    });
  }
}
