import { ReduxActionPayload } from './types/index.d';
import {
  ActionLoadFormPayload,
  ActionNextStepPayload,
  ActionRecordAnswerPayload,
  ActionRecordLeadPayload,
  ActionSetDemoSubmissionPayload,
} from './types/form.actions.d';
import { ActionTypes } from './types/type.actions.d';

export function actionLoadForm(
  payload: ActionLoadFormPayload,
): ReduxActionPayload {
  return {
    type: ActionTypes.LOAD_FORM,
    payload,
  };
}

export function actionFormNextStep(
  payload: ActionNextStepPayload,
): ReduxActionPayload {
  return {
    type: ActionTypes.FORM_NEXT_STEP,
    payload,
  };
}

export function actionFormPreviousStep(): ReduxActionPayload {
  return {
    type: ActionTypes.FORM_PREVIOUS_STEP,
  };
}

export function actionFormRecordAnswer(
  payload: ActionRecordAnswerPayload,
): ReduxActionPayload {
  return {
    type: ActionTypes.RECORD_ANSWER,
    payload,
  };
}

export function actionFormResetAnswers() {
  return {
    type: ActionTypes.RESET_ANSWERS,
  };
}

export function actionFormRecordLead(payload: ActionRecordLeadPayload) {
  return {
    type: ActionTypes.RECORD_LEAD,
    payload,
  };
}

export function actionGiveConsent() {
  return {
    type: ActionTypes.GIVE_CONSENT,
  };
}

export function actionRemoveConsent() {
  return {
    type: ActionTypes.REMOVE_CONSENT,
  };
}

export function actionSetDemoSubmission(
  payload: ActionSetDemoSubmissionPayload,
) {
  return {
    type: ActionTypes.SET_DEMO_SUBMISSION,
    payload,
  };
}
