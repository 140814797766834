import React from 'react';
import { motion } from 'framer-motion';
import APIService from '../../services/api/index.api';
import MoneyAmount from '../MoneyAmount';
import './index.scss';
import { StatsType } from '../../services/api/types/stats.response.d';
import { useSelector } from 'react-redux';
import { getFormDemoSubmission } from '../../store/selectors';

export interface SubmitScreenProps {
  leadId: number;
  logoImageId?: number;
  mainAnswer: string;
  title: string;
  description: string;
  price: number;
  clientKey: string;
  phoneNumber: string;
  belowButtonText: string;
  phoneCtaText: string;
  startingFromText: string;
  resultScreenButtonRedirect?: string | null;
}

function SubmitScreen({
  leadId,
  logoImageId,
  mainAnswer,
  title,
  description,
  price,
  clientKey,
  phoneNumber,
  belowButtonText,
  phoneCtaText,
  startingFromText,
  resultScreenButtonRedirect,
}: SubmitScreenProps) {
  const isDemoSubmission = useSelector(getFormDemoSubmission);
  const phoneCtaCallback = React.useCallback(() => {
    if (!isDemoSubmission) {
      APIService.statsService.report({
        type: StatsType.START_PHONE_CALL_SUBMIT,
        leadId,
        clientKey,
      });
    }
    if (resultScreenButtonRedirect?.length) {
      window.open(resultScreenButtonRedirect, '_blank');
    } else {
      window.open(`tel:${phoneNumber}`, '_parent');
    }
  }, [phoneNumber, leadId, clientKey, resultScreenButtonRedirect]);

  const BASE_DELAY = 0.2;

  return (
    <div className="submit-screen-component-container">
      <div className="submit-screen-component-container-top">
        {logoImageId ? (
          <motion.img
            className="submit-screen-component-container-top-image"
            src={APIService.imagesService.createImageUrl(logoImageId)}
            initial={{ opacity: 0, translateY: '15%' }}
            animate={{ opacity: 1, translateY: '0%' }}
            transition={{
              duration: 0.7,
              delay: BASE_DELAY + 0,
              ease: 'anticipate',
            }}
          />
        ) : null}
        <div
          className={`submit-screen-component-container-top-right ${
            !logoImageId ? 'no-image' : ''
          }`}
        >
          <motion.div
            className="submit-screen-component-container-top-right-label"
            initial={{ opacity: 0, translateY: '15%' }}
            animate={{ opacity: 1, translateY: '0%' }}
            transition={{
              duration: 0.7,
              delay: BASE_DELAY + 0.15,
              ease: 'anticipate',
            }}
          >
            {mainAnswer}
          </motion.div>
          <motion.h1
            className={`submit-screen-component-container-top-right-title ${
              !logoImageId ? 'no-image' : ''
            }`}
            initial={{ opacity: 0, translateY: '15%' }}
            animate={{ opacity: 1, translateY: '0%' }}
            transition={{
              duration: 0.7,
              delay: BASE_DELAY + 0.25,
              ease: 'anticipate',
            }}
          >
            {title}
          </motion.h1>
          <motion.p
            className={`submit-screen-component-container-top-right-description ${
              !logoImageId ? 'no-image' : ''
            }`}
            initial={{ opacity: 0, translateY: '15%' }}
            animate={{ opacity: 1, translateY: '0%' }}
            transition={{
              duration: 0.7,
              delay: BASE_DELAY + 0.35,
              ease: 'anticipate',
            }}
          >
            {description}
          </motion.p>
          {price !== null ? (
            <motion.div
              className={`submit-screen-component-container-top-right-price ${
                !logoImageId ? 'no-image' : ''
              }`}
              initial={{ opacity: 0, translateY: '15%' }}
              animate={{
                opacity: 1,
                translateY: '0%',
                transition: {
                  duration: 0.7,
                  delay: BASE_DELAY + 0.4,
                  ease: 'anticipate',
                },
              }}
            >
              <span className="submit-screen-component-container-top-right-price-label">
                {startingFromText}
              </span>
              <MoneyAmount
                className="submit-screen-component-container-top-right-price-amount"
                cents={price}
              />
            </motion.div>
          ) : null}
          {phoneNumber?.length || resultScreenButtonRedirect ? (
            <>
              <motion.button
                className={`submit-screen-component-container-top-right-quote ${
                  !logoImageId ? 'no-image' : ''
                }`}
                onClick={phoneCtaCallback}
                initial={{ opacity: 0, translateY: '15%' }}
                animate={{
                  opacity: 1,
                  translateY: '0%',
                  transition: {
                    duration: 0.7,
                    delay: BASE_DELAY + 0.5,
                    ease: 'anticipate',
                  },
                }}
                whileHover={{
                  transition: {
                    duration: 0.12,
                    delay: 0,
                    ease: 'easeIn',
                  },
                  scale: 1.03,
                  filter: 'brightness(1.3)',
                }}
                whileTap={{
                  transition: {
                    duration: 0.1,
                    delay: 0,
                    ease: 'easeIn',
                  },
                  scale: 0.95,
                  filter: 'brightness(0.7)',
                }}
              >
                {phoneCtaText}
              </motion.button>
              <motion.div
                className="submit-screen-component-container-top-right-notice"
                initial={{ opacity: 0, translateY: '15%' }}
                animate={{
                  opacity: 1,
                  translateY: '0%',
                  transition: {
                    duration: 0.7,
                    delay: BASE_DELAY + 0.6,
                    ease: 'anticipate',
                  },
                }}
              >
                <span className="submit-screen-component-container-top-right-notice-text">
                  {belowButtonText}
                </span>
              </motion.div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SubmitScreen;
