import { ReduxActionPayload } from '../actions/types/index.d';
import {
  ActionDeleteResponse,
  ActionLoadResponsePayload,
} from '../actions/types/responses.actions.d';
import { ActionTypes } from '../actions/types/type.actions.d';
import { ResponsesState } from './types/responses.types.d';

const defaultState: ResponsesState = {
  responses: {},
};

function responsesReducer(
  state = defaultState,
  action: ReduxActionPayload,
): ResponsesState {
  switch (action.type) {
    case ActionTypes.LOAD_RESPONSE: {
      const actionPayload = action.payload as ActionLoadResponsePayload;
      return {
        ...state,
        responses: {
          ...state.responses,
          [actionPayload.key]: {
            status: actionPayload.status,
            response: actionPayload.body,
          },
        },
      };
    }

    case ActionTypes.DELETE_RESPONSE: {
      const actionPayload = action.payload as ActionDeleteResponse;
      const newState = {
        ...state,
        responses: {
          ...state.responses,
        },
      };
      delete newState.responses[actionPayload.key];
      return newState;
    }

    default:
      return state;
  }
}

export default responsesReducer;
