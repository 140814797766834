import { ReduxActionPayload } from './types/index.d';
import { ActionTypes } from './types/type.actions.d';
import {
  OpenUiActionPayload,
  RemoveAnimatingComponentsActionPayload,
  UpdateAnimatingComponentsActionPayload,
} from './types/ui.actions';

export function actionUIOpen(
  payload?: OpenUiActionPayload,
): ReduxActionPayload {
  return {
    type: ActionTypes.OPEN,
    payload: {
      isFromLink: payload?.isFromLink || false,
      isFixed: payload?.isFixed || false,
    },
  };
}

export function actionUIClose(): ReduxActionPayload {
  return {
    type: ActionTypes.CLOSE,
    payload: {},
  };
}

export function actionUpdateAnimatingComponents(
  payload: UpdateAnimatingComponentsActionPayload,
): ReduxActionPayload {
  return {
    type: ActionTypes.UPDATE_ANIMATING_COMPONENTS,
    payload,
  };
}

export function actionRemoveAnimatingComponents(
  payload: RemoveAnimatingComponentsActionPayload,
): ReduxActionPayload {
  return {
    type: ActionTypes.REMOVE_ANIMATING_COMPONENTS,
    payload,
  };
}
