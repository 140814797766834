import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import APIService from '../../services/api/index.api';
import {
  FormsLoadClientStepOption,
  InputType,
} from '../../services/api/types/forms.responses.d';
import { actionFormRecordAnswer } from '../../store/actions/form.actions';
import {
  getAnimatingComponents,
  getFormStateSelector,
} from '../../store/selectors';
import './index.scss';
import { AnimatePresence, motion } from 'framer-motion';
import {
  actionRemoveAnimatingComponents,
  actionUpdateAnimatingComponents,
} from '../../store/actions/ui.actions';
import Input from '../Input';
import SingleSelectNoIcon from '../SingleSelectNoIcon';

export interface StepInputProps {
  inputType: InputType;
  inputId: number;
  stepId: number;
  options: FormsLoadClientStepOption[] | null;
  className?: string;
  onSelect?: (optionId: number) => void;
  motionDelay?: number;
  isFirstTime: boolean;
  inputQuestion?: string;
  inputLabel?: string | null;
  onProceed?: () => void;
}

function StepInput({
  inputType,
  options,
  inputId,
  stepId,
  className = '',
  onSelect,
  motionDelay = 0,
  isFirstTime = true,
  inputQuestion,
  inputLabel,
  onProceed,
}: StepInputProps) {
  const { stepAnswers } = useSelector(getFormStateSelector);
  const dispatch = useDispatch();
  const componentsAnimating = useSelector(getAnimatingComponents);

  const activeOptionIds = React.useMemo(() => {
    if (stepAnswers?.[stepId]?.answers[inputId]) {
      return stepAnswers[stepId].answers[inputId].optionId;
    }
  }, [stepAnswers, stepId, inputId]);

  const activeValue = React.useMemo(() => {
    if (stepAnswers?.[stepId]?.answers[inputId]) {
      return stepAnswers[stepId].answers[inputId].value;
    }
  }, [stepAnswers, stepId, inputId]);

  const initialDelay = React.useRef(isFirstTime ? motionDelay : 0.5);
  const rendered = React.useRef<Record<number, boolean>>({});

  React.useEffect(() => {
    initialDelay.current = motionDelay;
  }, [motionDelay]);

  React.useEffect(() => {
    rendered.current = {};
  }, [stepId]);

  React.useEffect(() => {
    return () => {
      dispatch(actionRemoveAnimatingComponents({ components: ['options'] }));
    };
  }, []);

  return (
    <div
      className={`step-input-component-container ${className} ${
        (options?.length || 0) * 220 > 960 ||
        inputType === InputType.SINGLE_SELECT_NO_ICON
          ? 'bottom-padding'
          : ''
      } ${inputType}`}
    >
      <div className={`step-input-component-holder ${inputType}`}>
        <AnimatePresence
          mode="wait"
          onExitComplete={() => {
            dispatch(
              actionRemoveAnimatingComponents({ components: ['options'] }),
            );
          }}
        >
          {options &&
          (inputType === InputType.SINGLE_SELECT_NO_ICON ||
            inputType === InputType.MULTIPLE_SELECT_NO_ICON) ? (
            <SingleSelectNoIcon
              options={options}
              initialDelay={initialDelay.current}
              activeOptionIds={activeOptionIds}
              multiple={inputType === InputType.MULTIPLE_SELECT_NO_ICON}
              onSelect={(optionSelected) => {
                if (!componentsAnimating.length) {
                  if (inputType === InputType.SINGLE_SELECT_NO_ICON) {
                    dispatch(
                      actionFormRecordAnswer({
                        stepId,
                        inputId,
                        inputAnswer: {
                          optionId: [optionSelected.id],
                          value: null,
                        },
                      }),
                    );
                  } else {
                    if (activeOptionIds?.includes(optionSelected.id)) {
                      // Unselect multiple
                      dispatch(
                        actionFormRecordAnswer({
                          stepId,
                          inputId,
                          inputAnswer: {
                            optionId:
                              activeOptionIds?.filter(
                                (aoi) => aoi !== optionSelected.id,
                              ) || [],
                            value: null,
                          },
                        }),
                      );
                    } else {
                      // Select multiple
                      dispatch(
                        actionFormRecordAnswer({
                          stepId,
                          inputId,
                          inputAnswer: {
                            optionId: activeOptionIds?.length
                              ? [...activeOptionIds, optionSelected.id]
                              : [optionSelected.id],
                            value: null,
                          },
                        }),
                      );
                    }
                  }
                  if (onSelect) {
                    onSelect(optionSelected.id);
                  }
                }
              }}
            />
          ) : inputType === InputType.SINGLE_SELECT ||
            inputType === InputType.MULTIPLE_SELECT ? (
            options?.map((option, index) => {
              return (
                <motion.div
                  key={`${inputId}_${option.id}_step-input-option`}
                  className={`step-input-component-option-container ${
                    activeOptionIds?.includes(option.id) ? 'active' : ''
                  }`}
                  initial={{
                    opacity: 0,
                    translateY: '15%',
                    borderColor: activeOptionIds?.includes(option.id)
                      ? 'var(--brand-color)'
                      : 'rgba(0,0,0,0)',
                    scale: activeOptionIds?.includes(option.id) ? 1.05 : 1,
                  }}
                  onLoad={() => {
                    rendered.current[option.id] = true;
                    dispatch(
                      actionUpdateAnimatingComponents({
                        components: ['options'],
                      }),
                    );
                  }}
                  onAnimationComplete={() => {
                    rendered.current[option.id] = true;
                    if (componentsAnimating.includes('options')) {
                      dispatch(
                        actionRemoveAnimatingComponents({
                          components: ['options'],
                        }),
                      );
                    }
                  }}
                  animate={{
                    opacity: 1,
                    translateY: '0%',
                    transition: {
                      duration: 0.7,
                      delay: rendered.current[option.id]
                        ? 0
                        : initialDelay.current + 0.35 + index * 0.05,
                      ease: 'anticipate',
                    },
                    borderColor: activeOptionIds?.includes(option.id)
                      ? 'var(--brand-color)'
                      : undefined,
                    scale: activeOptionIds?.includes(option.id)
                      ? 1.05
                      : undefined,
                    // backgroundColor: activeOptionIds?.includes(option.id)
                    //   ? 'var(--form-input-option-active-background)'
                    //   : undefined,
                  }}
                  style={{
                    order: option.order,
                  }}
                  exit={{
                    opacity: 0,
                    transition: {
                      duration: 0.4,
                      delay: 0,
                      ease: 'anticipate',
                    },
                    pointerEvents: 'none',
                  }}
                  whileHover={{
                    transition: {
                      duration: 0.15,
                      delay: 0,
                      ease: 'easeIn',
                    },
                    backgroundColor:
                      'var(--form-input-option-active-background)',
                    scale: activeOptionIds?.includes(option.id) ? 1.05 : 1.03,
                  }}
                  whileTap={{
                    transition: {
                      duration: 0.1,
                      delay: 0,
                      ease: 'easeIn',
                    },
                    borderColor: 'var(--brand-color)',
                    scale: 0.95,
                    backgroundColor:
                      'var(--form-input-option-active-background)',
                  }}
                  onClick={() => {
                    if (!componentsAnimating.length) {
                      if (inputType === InputType.SINGLE_SELECT) {
                        dispatch(
                          actionFormRecordAnswer({
                            stepId,
                            inputId,
                            inputAnswer: {
                              optionId: [option.id],
                              value: null,
                            },
                          }),
                        );
                      } else {
                        if (activeOptionIds?.includes(option.id)) {
                          dispatch(
                            actionFormRecordAnswer({
                              stepId,
                              inputId,
                              inputAnswer: {
                                optionId: activeOptionIds.filter(
                                  (aoi) => aoi !== option.id,
                                ),
                                value: null,
                              },
                            }),
                          );
                        } else {
                          dispatch(
                            actionFormRecordAnswer({
                              stepId,
                              inputId,
                              inputAnswer: {
                                optionId: activeOptionIds?.length
                                  ? [...activeOptionIds, option.id]
                                  : [option.id],
                                value: null,
                              },
                            }),
                          );
                        }
                      }
                      if (onSelect) {
                        onSelect(option.id);
                      }
                    }
                  }}
                >
                  {option.imageId ? (
                    <img
                      className="step-input-component-option-image"
                      src={APIService.imagesService.createImageUrl(
                        option.imageId,
                      )}
                      onLoad={(e: any) => e.target.classList.add('loaded')}
                    />
                  ) : null}
                  <span className="step-input-component-option-label">
                    {option.value}
                  </span>
                </motion.div>
              );
            })
          ) : (
            <Input
              key={`${inputId}`}
              variant="large"
              className="step-input-component-text-input"
              label={inputLabel || inputQuestion}
              tooltip={inputQuestion}
              defaultValue={(activeValue as any) || ''}
              motionDelay={initialDelay.current + 0.5}
              motionDuration={0.5}
              defaultFocused={true}
              onKeyUp={(e) => {
                if (e?.key === 'Enter' && onProceed) {
                  onProceed();
                }
              }}
              onChange={(value) => {
                dispatch(
                  actionFormRecordAnswer({
                    stepId,
                    inputId,
                    inputAnswer: {
                      optionId: null,
                      value: value || null,
                    },
                  }),
                );
              }}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default StepInput;
