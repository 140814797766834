import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FormsLoadClientStepOption } from '../../services/api/types/forms.responses';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionRemoveAnimatingComponents,
  actionUpdateAnimatingComponents,
} from '../../store/actions/ui.actions';
import './index.scss';
import { getAnimatingComponents } from '../../store/selectors';

export interface SingleSelectNoIconProps {
  className?: string;
  style?: React.CSSProperties;
  options: FormsLoadClientStepOption[];
  initialDelay?: number;
  activeOptionIds?: number[] | null;
  onSelect?: (option: FormsLoadClientStepOption) => void;
  multiple?: boolean;
}

function SingleSelectNoIcon({
  className = '',
  style,
  options,
  initialDelay = 0,
  activeOptionIds = null,
  onSelect,
  multiple = false,
}: SingleSelectNoIconProps) {
  const dispatch = useDispatch();
  const componentsAnimating = useSelector(getAnimatingComponents);

  console.log(activeOptionIds);

  return (
    <div
      className={`single-select-no-icon-component-container ${className}`}
      style={style}
    >
      <AnimatePresence
        mode="wait"
        onExitComplete={() => {
          dispatch(
            actionRemoveAnimatingComponents({ components: ['options'] }),
          );
        }}
      >
        {options.map((option, index) => {
          const dotRef =
            React.createRef() as React.MutableRefObject<HTMLSpanElement>;
          const dotInnerRef =
            React.createRef() as React.MutableRefObject<HTMLSpanElement>;
          return (
            <motion.div
              key={option.id}
              className={`single-select-no-icon-component-option ${
                activeOptionIds?.includes(option.id) ? 'active' : ''
              }`}
              style={{ order: option.order }}
              initial={{
                opacity: 0,
                translateY: '15%',
                borderColor: activeOptionIds?.includes(option.id)
                  ? 'var(--brand-color)'
                  : undefined,
              }}
              onLoad={() => {
                dispatch(
                  actionUpdateAnimatingComponents({
                    components: ['options'],
                  }),
                );
              }}
              onAnimationComplete={() => {
                if (componentsAnimating.includes('options')) {
                  dispatch(
                    actionRemoveAnimatingComponents({
                      components: ['options'],
                    }),
                  );
                }
              }}
              animate={{
                opacity: 1,
                translateY: '0%',
                transition: {
                  duration: 0.7,
                  delay: initialDelay + 0.35 + index * 0.05,
                  ease: 'anticipate',
                },
                borderColor: activeOptionIds?.includes(option.id)
                  ? 'var(--brand-color)'
                  : undefined,
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.4,
                  delay: 0,
                  ease: 'anticipate',
                },
                pointerEvents: 'none',
              }}
              whileHover={{
                transition: {
                  duration: 0.15,
                  delay: 0,
                  ease: 'easeIn',
                },
                borderColor: '#E1E3E7',
                backgroundColor: '#FCFCFC',
                boxShadow: '0 15px 24px 0 rgba(12, 22, 35, 0.05)',
                scale: 1.03,
              }}
              whileTap={{
                transition: {
                  duration: 0.1,
                  delay: 0,
                  ease: 'easeIn',
                },
                borderColor: 'var(--brand-color)',
                scale: 0.95,
                backgroundColor: '#FCFCFC',
              }}
              onClick={() => {
                if (onSelect) {
                  onSelect(option);
                }
                if (dotRef.current && dotInnerRef.current) {
                  dotRef.current.classList.add('active');
                  dotInnerRef.current.classList.add('active');
                }
              }}
            >
              <span
                className={`single-select-no-icon-component-option-dot ${
                  activeOptionIds?.includes(option.id) ? 'active' : ''
                } ${multiple ? 'multiple' : ''}`}
                ref={dotRef as any}
              >
                <span
                  ref={dotInnerRef as any}
                  className={`single-select-no-icon-component-option-dot-inner ${
                    activeOptionIds?.includes(option.id) ? 'active' : ''
                  } ${multiple ? 'multiple' : ''}`}
                />
              </span>
              <span className="single-select-no-icon-component-label">
                {option.value}
              </span>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
  );
}

export default SingleSelectNoIcon;
