import { SendRequestMessageData } from '../parentApp/types/messages';
import { APIBaseService } from './base.api';
import ParentAppService from '../parentApp';
import { StatsReportRequest } from './types/stats.response.d';

export class APIStatsService extends APIBaseService {
  static report(body: StatsReportRequest, uuidKey?: string): string {
    const uuid = uuidKey || ParentAppService.generateUUID();
    const url = new URL(`${APIBaseService.apiUrl}/api/stats/report`).href;
    const data: SendRequestMessageData = {
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      url,
      uuid,
    };
    ParentAppService.sendRequestMessage(data);
    return uuid;
  }
}
