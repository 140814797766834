export interface PhoneCode {
  name: string;
  flag: string;
  code: string;
  dial_code: string;
}

export const PHONE_CODES: PhoneCode[] = [
  { name: 'Afghanistan', flag: '🇦🇫', code: 'AF', dial_code: '+93' },
  { name: 'Åland Islands', flag: '🇦🇽', code: 'AX', dial_code: '+358' },
  { name: 'Albania', flag: '🇦🇱', code: 'AL', dial_code: '+355' },
  { name: 'Algeria', flag: '🇩🇿', code: 'DZ', dial_code: '+213' },
  {
    name: 'American Samoa',
    flag: '🇦🇸',
    code: 'AS',
    dial_code: '+1684',
  },
  { name: 'Andorra', flag: '🇦🇩', code: 'AD', dial_code: '+376' },
  { name: 'Angola', flag: '🇦🇴', code: 'AO', dial_code: '+244' },
  { name: 'Anguilla', flag: '🇦🇮', code: 'AI', dial_code: '+1264' },
  { name: 'Antarctica', flag: '🇦🇶', code: 'AQ', dial_code: '+672' },
  {
    name: 'Antigua and Barbuda',
    flag: '🇦🇬',
    code: 'AG',
    dial_code: '+1268',
  },
  { name: 'Argentina', flag: '🇦🇷', code: 'AR', dial_code: '+54' },
  { name: 'Armenia', flag: '🇦🇲', code: 'AM', dial_code: '+374' },
  { name: 'Aruba', flag: '🇦🇼', code: 'AW', dial_code: '+297' },
  { name: 'Australia', flag: '🇦🇺', code: 'AU', dial_code: '+61' },
  { name: 'Austria', flag: '🇦🇹', code: 'AT', dial_code: '+43' },
  { name: 'Azerbaijan', flag: '🇦🇿', code: 'AZ', dial_code: '+994' },
  { name: 'Bahamas', flag: '🇧🇸', code: 'BS', dial_code: '+1242' },
  { name: 'Bahrain', flag: '🇧🇭', code: 'BH', dial_code: '+973' },
  { name: 'Bangladesh', flag: '🇧🇩', code: 'BD', dial_code: '+880' },
  { name: 'Barbados', flag: '🇧🇧', code: 'BB', dial_code: '+1246' },
  { name: 'Belarus', flag: '🇧🇾', code: 'BY', dial_code: '+375' },
  { name: 'Belgium', flag: '🇧🇪', code: 'BE', dial_code: '+32' },
  { name: 'Belize', flag: '🇧🇿', code: 'BZ', dial_code: '+501' },
  { name: 'Benin', flag: '🇧🇯', code: 'BJ', dial_code: '+229' },
  { name: 'Bermuda', flag: '🇧🇲', code: 'BM', dial_code: '+1441' },
  { name: 'Bhutan', flag: '🇧🇹', code: 'BT', dial_code: '+975' },
  {
    name: 'Bolivia, Plurinational State of bolivia',
    flag: '🇧🇴',
    code: 'BO',
    dial_code: '+591',
  },
  {
    name: 'Bosnia and Herzegovina',
    flag: '🇧🇦',
    code: 'BA',
    dial_code: '+387',
  },
  { name: 'Botswana', flag: '🇧🇼', code: 'BW', dial_code: '+267' },
  { name: 'Bouvet Island', flag: '🇧🇻', code: 'BV', dial_code: '+47' },
  { name: 'Brazil', flag: '🇧🇷', code: 'BR', dial_code: '+55' },
  {
    name: 'British Indian Ocean Territory',
    flag: '🇮🇴',
    code: 'IO',
    dial_code: '+246',
  },
  {
    name: 'Brunei Darussalam',
    flag: '🇧🇳',
    code: 'BN',
    dial_code: '+673',
  },
  { name: 'Bulgaria', flag: '🇧🇬', code: 'BG', dial_code: '+359' },
  { name: 'Burkina Faso', flag: '🇧🇫', code: 'BF', dial_code: '+226' },
  { name: 'Burundi', flag: '🇧🇮', code: 'BI', dial_code: '+257' },
  { name: 'Cambodia', flag: '🇰🇭', code: 'KH', dial_code: '+855' },
  { name: 'Cameroon', flag: '🇨🇲', code: 'CM', dial_code: '+237' },
  { name: 'Canada', flag: '🇨🇦', code: 'CA', dial_code: '+1' },
  { name: 'Cape Verde', flag: '🇨🇻', code: 'CV', dial_code: '+238' },
  { name: 'Cayman Islands', flag: '🇰🇾', code: 'KY', dial_code: '+345' },
  {
    name: 'Central African Republic',
    flag: '🇨🇫',
    code: 'CF',
    dial_code: '+236',
  },
  { name: 'Chad', flag: '🇹🇩', code: 'TD', dial_code: '+235' },
  { name: 'Chile', flag: '🇨🇱', code: 'CL', dial_code: '+56' },
  { name: 'China', flag: '🇨🇳', code: 'CN', dial_code: '+86' },
  {
    name: 'Christmas Island',
    flag: '🇨🇽',
    code: 'CX',
    dial_code: '+61',
  },
  {
    name: 'Cocos (Keeling) Islands',
    flag: '🇨🇨',
    code: 'CC',
    dial_code: '+61',
  },
  { name: 'Colombia', flag: '🇨🇴', code: 'CO', dial_code: '+57' },
  { name: 'Comoros', flag: '🇰🇲', code: 'KM', dial_code: '+269' },
  { name: 'Congo', flag: '🇨🇬', code: 'CG', dial_code: '+242' },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    flag: '🇨🇩',
    code: 'CD',
    dial_code: '+243',
  },
  { name: 'Cook Islands', flag: '🇨🇰', code: 'CK', dial_code: '+682' },
  { name: 'Costa Rica', flag: '🇨🇷', code: 'CR', dial_code: '+506' },
  { name: "Cote d'Ivoire", flag: '🇨🇮', code: 'CI', dial_code: '+225' },
  { name: 'Croatia', flag: '🇭🇷', code: 'HR', dial_code: '+385' },
  { name: 'Cuba', flag: '🇨🇺', code: 'CU', dial_code: '+53' },
  { name: 'Cyprus', flag: '🇨🇾', code: 'CY', dial_code: '+357' },
  { name: 'Czech Republic', flag: '🇨🇿', code: 'CZ', dial_code: '+420' },
  { name: 'Denmark', flag: '🇩🇰', code: 'DK', dial_code: '+45' },
  { name: 'Djibouti', flag: '🇩🇯', code: 'DJ', dial_code: '+253' },
  { name: 'Dominica', flag: '🇩🇲', code: 'DM', dial_code: '+1767' },
  {
    name: 'Dominican Republic',
    flag: '🇩🇴',
    code: 'DO',
    dial_code: '+1849',
  },
  { name: 'Ecuador', flag: '🇪🇨', code: 'EC', dial_code: '+593' },
  { name: 'Egypt', flag: '🇪🇬', code: 'EG', dial_code: '+20' },
  { name: 'El Salvador', flag: '🇸🇻', code: 'SV', dial_code: '+503' },
  {
    name: 'Equatorial Guinea',
    flag: '🇬🇶',
    code: 'GQ',
    dial_code: '+240',
  },
  { name: 'Eritrea', flag: '🇪🇷', code: 'ER', dial_code: '+291' },
  { name: 'Estonia', flag: '🇪🇪', code: 'EE', dial_code: '+372' },
  { name: 'Ethiopia', flag: '🇪🇹', code: 'ET', dial_code: '+251' },
  {
    name: 'Falkland Islands (Malvinas)',
    flag: '🇫🇰',
    code: 'FK',
    dial_code: '+500',
  },
  { name: 'Faroe Islands', flag: '🇫🇴', code: 'FO', dial_code: '+298' },
  { name: 'Fiji', flag: '🇫🇯', code: 'FJ', dial_code: '+679' },
  { name: 'Finland', flag: '🇫🇮', code: 'FI', dial_code: '+358' },
  { name: 'France', flag: '🇫🇷', code: 'FR', dial_code: '+33' },
  { name: 'French Guiana', flag: '🇬🇫', code: 'GF', dial_code: '+594' },
  {
    name: 'French Polynesia',
    flag: '🇵🇫',
    code: 'PF',
    dial_code: '+689',
  },
  {
    name: 'French Southern Territories',
    flag: '🇹🇫',
    code: 'TF',
    dial_code: '+262',
  },
  { name: 'Gabon', flag: '🇬🇦', code: 'GA', dial_code: '+241' },
  { name: 'Gambia', flag: '🇬🇲', code: 'GM', dial_code: '+220' },
  { name: 'Georgia', flag: '🇬🇪', code: 'GE', dial_code: '+995' },
  { name: 'Germany', flag: '🇩🇪', code: 'DE', dial_code: '+49' },
  { name: 'Ghana', flag: '🇬🇭', code: 'GH', dial_code: '+233' },
  { name: 'Gibraltar', flag: '🇬🇮', code: 'GI', dial_code: '+350' },
  { name: 'Greece', flag: '🇬🇷', code: 'GR', dial_code: '+30' },
  { name: 'Greenland', flag: '🇬🇱', code: 'GL', dial_code: '+299' },
  { name: 'Grenada', flag: '🇬🇩', code: 'GD', dial_code: '+1473' },
  { name: 'Guadeloupe', flag: '🇬🇵', code: 'GP', dial_code: '+590' },
  { name: 'Guam', flag: '🇬🇺', code: 'GU', dial_code: '+1671' },
  { name: 'Guatemala', flag: '🇬🇹', code: 'GT', dial_code: '+502' },
  { name: 'Guernsey', flag: '🇬🇬', code: 'GG', dial_code: '+44' },
  { name: 'Guinea', flag: '🇬🇳', code: 'GN', dial_code: '+224' },
  { name: 'Guinea-Bissau', flag: '🇬🇼', code: 'GW', dial_code: '+245' },
  { name: 'Guyana', flag: '🇬🇾', code: 'GY', dial_code: '+592' },
  { name: 'Haiti', flag: '🇭🇹', code: 'HT', dial_code: '+509' },
  {
    name: 'Heard Island and Mcdonald Islands',
    flag: '🇭🇲',
    code: 'HM',
    dial_code: '+672',
  },
  {
    name: 'Holy See (Vatican City State)',
    flag: '🇻🇦',
    code: 'VA',
    dial_code: '+379',
  },
  { name: 'Honduras', flag: '🇭🇳', code: 'HN', dial_code: '+504' },
  { name: 'Hong Kong', flag: '🇭🇰', code: 'HK', dial_code: '+852' },
  { name: 'Hungary', flag: '🇭🇺', code: 'HU', dial_code: '+36' },
  { name: 'Iceland', flag: '🇮🇸', code: 'IS', dial_code: '+354' },
  { name: 'India', flag: '🇮🇳', code: 'IN', dial_code: '+91' },
  { name: 'Indonesia', flag: '🇮🇩', code: 'ID', dial_code: '+62' },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    flag: '🇮🇷',
    code: 'IR',
    dial_code: '+98',
  },
  { name: 'Iraq', flag: '🇮🇶', code: 'IQ', dial_code: '+964' },
  { name: 'Ireland', flag: '🇮🇪', code: 'IE', dial_code: '+353' },
  { name: 'Isle of Man', flag: '🇮🇲', code: 'IM', dial_code: '+44' },
  { name: 'Israel', flag: '🇮🇱', code: 'IL', dial_code: '+972' },
  { name: 'Italy', flag: '🇮🇹', code: 'IT', dial_code: '+39' },
  { name: 'Jamaica', flag: '🇯🇲', code: 'JM', dial_code: '+1876' },
  { name: 'Japan', flag: '🇯🇵', code: 'JP', dial_code: '+81' },
  { name: 'Jersey', flag: '🇯🇪', code: 'JE', dial_code: '+44' },
  { name: 'Jordan', flag: '🇯🇴', code: 'JO', dial_code: '+962' },
  { name: 'Kazakhstan', flag: '🇰🇿', code: 'KZ', dial_code: '+7' },
  { name: 'Kenya', flag: '🇰🇪', code: 'KE', dial_code: '+254' },
  { name: 'Kiribati', flag: '🇰🇮', code: 'KI', dial_code: '+686' },
  {
    name: "Korea, Democratic People's Republic of Korea",
    flag: '🇰🇵',
    code: 'KP',
    dial_code: '+850',
  },
  {
    name: 'Korea, Republic of South Korea',
    flag: '🇰🇷',
    code: 'KR',
    dial_code: '+82',
  },
  { name: 'Kosovo', flag: '🇽🇰', code: 'XK', dial_code: '+383' },
  { name: 'Kuwait', flag: '🇰🇼', code: 'KW', dial_code: '+965' },
  { name: 'Kyrgyzstan', flag: '🇰🇬', code: 'KG', dial_code: '+996' },
  { name: 'Laos', flag: '🇱🇦', code: 'LA', dial_code: '+856' },
  { name: 'Latvia', flag: '🇱🇻', code: 'LV', dial_code: '+371' },
  { name: 'Lebanon', flag: '🇱🇧', code: 'LB', dial_code: '+961' },
  { name: 'Lesotho', flag: '🇱🇸', code: 'LS', dial_code: '+266' },
  { name: 'Liberia', flag: '🇱🇷', code: 'LR', dial_code: '+231' },
  {
    name: 'Libyan Arab Jamahiriya',
    flag: '🇱🇾',
    code: 'LY',
    dial_code: '+218',
  },
  { name: 'Liechtenstein', flag: '🇱🇮', code: 'LI', dial_code: '+423' },
  { name: 'Lithuania', flag: '🇱🇹', code: 'LT', dial_code: '+370' },
  { name: 'Luxembourg', flag: '🇱🇺', code: 'LU', dial_code: '+352' },
  { name: 'Macao', flag: '🇲🇴', code: 'MO', dial_code: '+853' },
  { name: 'Macedonia', flag: '🇲🇰', code: 'MK', dial_code: '+389' },
  { name: 'Madagascar', flag: '🇲🇬', code: 'MG', dial_code: '+261' },
  { name: 'Malawi', flag: '🇲🇼', code: 'MW', dial_code: '+265' },
  { name: 'Malaysia', flag: '🇲🇾', code: 'MY', dial_code: '+60' },
  { name: 'Maldives', flag: '🇲🇻', code: 'MV', dial_code: '+960' },
  { name: 'Mali', flag: '🇲🇱', code: 'ML', dial_code: '+223' },
  { name: 'Malta', flag: '🇲🇹', code: 'MT', dial_code: '+356' },
  {
    name: 'Marshall Islands',
    flag: '🇲🇭',
    code: 'MH',
    dial_code: '+692',
  },
  { name: 'Martinique', flag: '🇲🇶', code: 'MQ', dial_code: '+596' },
  { name: 'Mauritania', flag: '🇲🇷', code: 'MR', dial_code: '+222' },
  { name: 'Mauritius', flag: '🇲🇺', code: 'MU', dial_code: '+230' },
  { name: 'Mayotte', flag: '🇾🇹', code: 'YT', dial_code: '+262' },
  { name: 'Mexico', flag: '🇲🇽', code: 'MX', dial_code: '+52' },
  {
    name: 'Micronesia, Federated States of Micronesia',
    flag: '🇫🇲',
    code: 'FM',
    dial_code: '+691',
  },
  { name: 'Moldova', flag: '🇲🇩', code: 'MD', dial_code: '+373' },
  { name: 'Monaco', flag: '🇲🇨', code: 'MC', dial_code: '+377' },
  { name: 'Mongolia', flag: '🇲🇳', code: 'MN', dial_code: '+976' },
  { name: 'Montenegro', flag: '🇲🇪', code: 'ME', dial_code: '+382' },
  { name: 'Montserrat', flag: '🇲🇸', code: 'MS', dial_code: '+1664' },
  { name: 'Morocco', flag: '🇲🇦', code: 'MA', dial_code: '+212' },
  { name: 'Mozambique', flag: '🇲🇿', code: 'MZ', dial_code: '+258' },
  { name: 'Myanmar', flag: '🇲🇲', code: 'MM', dial_code: '+95' },
  { name: 'Namibia', flag: '🇳🇦', code: 'NA', dial_code: '+264' },
  { name: 'Nauru', flag: '🇳🇷', code: 'NR', dial_code: '+674' },
  { name: 'Nepal', flag: '🇳🇵', code: 'NP', dial_code: '+977' },
  { name: 'Netherlands', flag: '🇳🇱', code: 'NL', dial_code: '+31' },
  {
    name: 'Netherlands Antilles',
    flag: '',
    code: 'AN',
    dial_code: '+599',
  },
  { name: 'New Caledonia', flag: '🇳🇨', code: 'NC', dial_code: '+687' },
  { name: 'New Zealand', flag: '🇳🇿', code: 'NZ', dial_code: '+64' },
  { name: 'Nicaragua', flag: '🇳🇮', code: 'NI', dial_code: '+505' },
  { name: 'Niger', flag: '🇳🇪', code: 'NE', dial_code: '+227' },
  { name: 'Nigeria', flag: '🇳🇬', code: 'NG', dial_code: '+234' },
  { name: 'Niue', flag: '🇳🇺', code: 'NU', dial_code: '+683' },
  { name: 'Norfolk Island', flag: '🇳🇫', code: 'NF', dial_code: '+672' },
  {
    name: 'Northern Mariana Islands',
    flag: '🇲🇵',
    code: 'MP',
    dial_code: '+1670',
  },
  { name: 'Norway', flag: '🇳🇴', code: 'NO', dial_code: '+47' },
  { name: 'Oman', flag: '🇴🇲', code: 'OM', dial_code: '+968' },
  { name: 'Pakistan', flag: '🇵🇰', code: 'PK', dial_code: '+92' },
  { name: 'Palau', flag: '🇵🇼', code: 'PW', dial_code: '+680' },
  {
    name: 'Palestinian Territory, Occupied',
    flag: '🇵🇸',
    code: 'PS',
    dial_code: '+970',
  },
  { name: 'Panama', flag: '🇵🇦', code: 'PA', dial_code: '+507' },
  {
    name: 'Papua New Guinea',
    flag: '🇵🇬',
    code: 'PG',
    dial_code: '+675',
  },
  { name: 'Paraguay', flag: '🇵🇾', code: 'PY', dial_code: '+595' },
  { name: 'Peru', flag: '🇵🇪', code: 'PE', dial_code: '+51' },
  { name: 'Philippines', flag: '🇵🇭', code: 'PH', dial_code: '+63' },
  { name: 'Pitcairn', flag: '🇵🇳', code: 'PN', dial_code: '+64' },
  { name: 'Poland', flag: '🇵🇱', code: 'PL', dial_code: '+48' },
  { name: 'Portugal', flag: '🇵🇹', code: 'PT', dial_code: '+351' },
  { name: 'Puerto Rico', flag: '🇵🇷', code: 'PR', dial_code: '+1939' },
  { name: 'Qatar', flag: '🇶🇦', code: 'QA', dial_code: '+974' },
  { name: 'Romania', flag: '🇷🇴', code: 'RO', dial_code: '+40' },
  { name: 'Russia', flag: '🇷🇺', code: 'RU', dial_code: '+7' },
  { name: 'Rwanda', flag: '🇷🇼', code: 'RW', dial_code: '+250' },
  { name: 'Reunion', flag: '🇷🇪', code: 'RE', dial_code: '+262' },
  {
    name: 'Saint Barthelemy',
    flag: '🇧🇱',
    code: 'BL',
    dial_code: '+590',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    flag: '🇸🇭',
    code: 'SH',
    dial_code: '+290',
  },
  {
    name: 'Saint Kitts and Nevis',
    flag: '🇰🇳',
    code: 'KN',
    dial_code: '+1869',
  },
  { name: 'Saint Lucia', flag: '🇱🇨', code: 'LC', dial_code: '+1758' },
  { name: 'Saint Martin', flag: '🇲🇫', code: 'MF', dial_code: '+590' },
  {
    name: 'Saint Pierre and Miquelon',
    flag: '🇵🇲',
    code: 'PM',
    dial_code: '+508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    flag: '🇻🇨',
    code: 'VC',
    dial_code: '+1784',
  },
  { name: 'Samoa', flag: '🇼🇸', code: 'WS', dial_code: '+685' },
  { name: 'San Marino', flag: '🇸🇲', code: 'SM', dial_code: '+378' },
  {
    name: 'Sao Tome and Principe',
    flag: '🇸🇹',
    code: 'ST',
    dial_code: '+239',
  },
  { name: 'Saudi Arabia', flag: '🇸🇦', code: 'SA', dial_code: '+966' },
  { name: 'Senegal', flag: '🇸🇳', code: 'SN', dial_code: '+221' },
  { name: 'Serbia', flag: '🇷🇸', code: 'RS', dial_code: '+381' },
  { name: 'Seychelles', flag: '🇸🇨', code: 'SC', dial_code: '+248' },
  { name: 'Sierra Leone', flag: '🇸🇱', code: 'SL', dial_code: '+232' },
  { name: 'Singapore', flag: '🇸🇬', code: 'SG', dial_code: '+65' },
  { name: 'Slovakia', flag: '🇸🇰', code: 'SK', dial_code: '+421' },
  { name: 'Slovenia', flag: '🇸🇮', code: 'SI', dial_code: '+386' },
  {
    name: 'Solomon Islands',
    flag: '🇸🇧',
    code: 'SB',
    dial_code: '+677',
  },
  { name: 'Somalia', flag: '🇸🇴', code: 'SO', dial_code: '+252' },
  { name: 'South Africa', flag: '🇿🇦', code: 'ZA', dial_code: '+27' },
  { name: 'South Sudan', flag: '🇸🇸', code: 'SS', dial_code: '+211' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    flag: '🇬🇸',
    code: 'GS',
    dial_code: '+500',
  },
  { name: 'Spain', flag: '🇪🇸', code: 'ES', dial_code: '+34' },
  { name: 'Sri Lanka', flag: '🇱🇰', code: 'LK', dial_code: '+94' },
  { name: 'Sudan', flag: '🇸🇩', code: 'SD', dial_code: '+249' },
  { name: 'Suriname', flag: '🇸🇷', code: 'SR', dial_code: '+597' },
  {
    name: 'Svalbard and Jan Mayen',
    flag: '🇸🇯',
    code: 'SJ',
    dial_code: '+47',
  },
  { name: 'Eswatini', flag: '🇸🇿', code: 'SZ', dial_code: '+268' },
  { name: 'Sweden', flag: '🇸🇪', code: 'SE', dial_code: '+46' },
  { name: 'Switzerland', flag: '🇨🇭', code: 'CH', dial_code: '+41' },
  {
    name: 'Syrian Arab Republic',
    flag: '🇸🇾',
    code: 'SY',
    dial_code: '+963',
  },
  { name: 'Taiwan', flag: '🇹🇼', code: 'TW', dial_code: '+886' },
  { name: 'Tajikistan', flag: '🇹🇯', code: 'TJ', dial_code: '+992' },
  {
    name: 'Tanzania, United Republic of Tanzania',
    flag: '🇹🇿',
    code: 'TZ',
    dial_code: '+255',
  },
  { name: 'Thailand', flag: '🇹🇭', code: 'TH', dial_code: '+66' },
  { name: 'Timor-Leste', flag: '🇹🇱', code: 'TL', dial_code: '+670' },
  { name: 'Togo', flag: '🇹🇬', code: 'TG', dial_code: '+228' },
  { name: 'Tokelau', flag: '🇹🇰', code: 'TK', dial_code: '+690' },
  { name: 'Tonga', flag: '🇹🇴', code: 'TO', dial_code: '+676' },
  {
    name: 'Trinidad and Tobago',
    flag: '🇹🇹',
    code: 'TT',
    dial_code: '+1868',
  },
  { name: 'Tunisia', flag: '🇹🇳', code: 'TN', dial_code: '+216' },
  { name: 'Turkey', flag: '🇹🇷', code: 'TR', dial_code: '+90' },
  { name: 'Turkmenistan', flag: '🇹🇲', code: 'TM', dial_code: '+993' },
  {
    name: 'Turks and Caicos Islands',
    flag: '🇹🇨',
    code: 'TC',
    dial_code: '+1649',
  },
  { name: 'Tuvalu', flag: '🇹🇻', code: 'TV', dial_code: '+688' },
  { name: 'Uganda', flag: '🇺🇬', code: 'UG', dial_code: '+256' },
  { name: 'Ukraine', flag: '🇺🇦', code: 'UA', dial_code: '+380' },
  {
    name: 'United Arab Emirates',
    flag: '🇦🇪',
    code: 'AE',
    dial_code: '+971',
  },
  { name: 'United Kingdom', flag: '🇬🇧', code: 'GB', dial_code: '+44' },
  { name: 'United States', flag: '🇺🇸', code: 'US', dial_code: '+1' },
  { name: 'Uruguay', flag: '🇺🇾', code: 'UY', dial_code: '+598' },
  { name: 'Uzbekistan', flag: '🇺🇿', code: 'UZ', dial_code: '+998' },
  { name: 'Vanuatu', flag: '🇻🇺', code: 'VU', dial_code: '+678' },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    flag: '🇻🇪',
    code: 'VE',
    dial_code: '+58',
  },
  { name: 'Vietnam', flag: '🇻🇳', code: 'VN', dial_code: '+84' },
  {
    name: 'Virgin Islands, British',
    flag: '🇻🇬',
    code: 'VG',
    dial_code: '+1284',
  },
  {
    name: 'Virgin Islands, U.S.',
    flag: '🇻🇮',
    code: 'VI',
    dial_code: '+1340',
  },
  {
    name: 'Wallis and Futuna',
    flag: '🇼🇫',
    code: 'WF',
    dial_code: '+681',
  },
  { name: 'Yemen', flag: '🇾🇪', code: 'YE', dial_code: '+967' },
  { name: 'Zambia', flag: '🇿🇲', code: 'ZM', dial_code: '+260' },
  { name: 'Zimbabwe', flag: '🇿🇼', code: 'ZW', dial_code: '+263' },
];

export const TIMEZONE_TO_CODE: Record<string, Array<string | null>> = {
  ALL: [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Addis_Ababa',
    'Africa/Algiers',
    'Africa/Asmara',
    'Africa/Asmera',
    'Africa/Bamako',
    'Africa/Bangui',
    'Africa/Banjul',
    'Africa/Bissau',
    'Africa/Blantyre',
    'Africa/Brazzaville',
    'Africa/Bujumbura',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Ceuta',
    'Africa/Conakry',
    'Africa/Dakar',
    'Africa/Dar_es_Salaam',
    'Africa/Djibouti',
    'Africa/Douala',
    'Africa/El_Aaiun',
    'Africa/Freetown',
    'Africa/Gaborone',
    'Africa/Harare',
    'Africa/Johannesburg',
    'Africa/Juba',
    'Africa/Kampala',
    'Africa/Khartoum',
    'Africa/Kigali',
    'Africa/Kinshasa',
    'Africa/Lagos',
    'Africa/Libreville',
    'Africa/Lome',
    'Africa/Luanda',
    'Africa/Lubumbashi',
    'Africa/Lusaka',
    'Africa/Malabo',
    'Africa/Maputo',
    'Africa/Maseru',
    'Africa/Mbabane',
    'Africa/Mogadishu',
    'Africa/Monrovia',
    'Africa/Nairobi',
    'Africa/Ndjamena',
    'Africa/Niamey',
    'Africa/Nouakchott',
    'Africa/Ouagadougou',
    'Africa/Porto-Novo',
    'Africa/Sao_Tome',
    'Africa/Timbuktu',
    'Africa/Tripoli',
    'Africa/Tunis',
    'Africa/Windhoek',
    'America/Adak',
    'America/Anchorage',
    'America/Anguilla',
    'America/Antigua',
    'America/Araguaina',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca',
    'America/Argentina/ComodRivadavia',
    'America/Argentina/Cordoba',
    'America/Argentina/Jujuy',
    'America/Argentina/La_Rioja',
    'America/Argentina/Mendoza',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Salta',
    'America/Argentina/San_Juan',
    'America/Argentina/San_Luis',
    'America/Argentina/Tucuman',
    'America/Argentina/Ushuaia',
    'America/Aruba',
    'America/Asuncion',
    'America/Atikokan',
    'America/Atka',
    'America/Bahia',
    'America/Bahia_Banderas',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Blanc-Sablon',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Buenos_Aires',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Catamarca',
    'America/Cayenne',
    'America/Cayman',
    'America/Chicago',
    'America/Chihuahua',
    'America/Coral_Harbour',
    'America/Cordoba',
    'America/Costa_Rica',
    'America/Creston',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver',
    'America/Detroit',
    'America/Dominica',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Ensenada',
    'America/Fort_Wayne',
    'America/Fortaleza',
    'America/Glace_Bay',
    'America/Godthab',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Grenada',
    'America/Guadeloupe',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indianapolis',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Jujuy',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Knox_IN',
    'America/Kralendijk',
    'America/La_Paz',
    'America/Lima',
    'America/Los_Angeles',
    'America/Louisville',
    'America/Lower_Princes',
    'America/Maceio',
    'America/Managua',
    'America/Manaus',
    'America/Marigot',
    'America/Martinique',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Mendoza',
    'America/Menominee',
    'America/Merida',
    'America/Metlakatla',
    'America/Mexico_City',
    'America/Miquelon',
    'America/Moncton',
    'America/Monterrey',
    'America/Montevideo',
    'America/Montreal',
    'America/Montserrat',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Ojinaga',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Acre',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Rosario',
    'America/Santa_Isabel',
    'America/Santarem',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Shiprock',
    'America/Sitka',
    'America/St_Barthelemy',
    'America/St_Johns',
    'America/St_Kitts',
    'America/St_Lucia',
    'America/St_Thomas',
    'America/St_Vincent',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Tortola',
    'America/Vancouver',
    'America/Virgin',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Macquarie',
    'Antarctica/Mawson',
    'Antarctica/McMurdo',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/South_Pole',
    'Antarctica/Syowa',
    'Antarctica/Vostok',
    'Arctic/Longyearbyen',
    'Asia/Aden',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Anadyr',
    'Asia/Aqtau',
    'Asia/Aqtobe',
    'Asia/Ashgabat',
    'Asia/Ashkhabad',
    'Asia/Baghdad',
    'Asia/Bahrain',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Beirut',
    'Asia/Bishkek',
    'Asia/Brunei',
    'Asia/Calcutta',
    'Asia/Choibalsan',
    'Asia/Chongqing',
    'Asia/Chungking',
    'Asia/Colombo',
    'Asia/Dacca',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dili',
    'Asia/Dubai',
    'Asia/Dushanbe',
    'Asia/Gaza',
    'Asia/Harbin',
    'Asia/Hebron',
    'Asia/Ho_Chi_Minh',
    'Asia/Hong_Kong',
    'Asia/Hovd',
    'Asia/Irkutsk',
    'Asia/Istanbul',
    'Asia/Jakarta',
    'Asia/Jayapura',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Karachi',
    'Asia/Kashgar',
    'Asia/Kathmandu',
    'Asia/Katmandu',
    'Asia/Khandyga',
    'Asia/Kolkata',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Asia/Kuwait',
    'Asia/Macao',
    'Asia/Macau',
    'Asia/Magadan',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Muscat',
    'Asia/Nicosia',
    'Asia/Novokuznetsk',
    'Asia/Novosibirsk',
    'Asia/Omsk',
    'Asia/Oral',
    'Asia/Phnom_Penh',
    'Asia/Pontianak',
    'Asia/Pyongyang',
    'Asia/Qatar',
    'Asia/Qyzylorda',
    'Asia/Rangoon',
    'Asia/Riyadh',
    'Asia/Saigon',
    'Asia/Sakhalin',
    'Asia/Samarkand',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tbilisi',
    'Asia/Tehran',
    'Asia/Tel_Aviv',
    'Asia/Thimbu',
    'Asia/Thimphu',
    'Asia/Tokyo',
    'Asia/Ujung_Pandang',
    'Asia/Ulaanbaatar',
    'Asia/Ulan_Bator',
    'Asia/Urumqi',
    'Asia/Ust-Nera',
    'Asia/Vientiane',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yekaterinburg',
    'Asia/Yerevan',
    'Atlantic/Azores',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faeroe',
    'Atlantic/Faroe',
    'Atlantic/Jan_Mayen',
    'Atlantic/Madeira',
    'Atlantic/Reykjavik',
    'Atlantic/South_Georgia',
    'Atlantic/St_Helena',
    'Atlantic/Stanley',
    'Australia/ACT',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Canberra',
    'Australia/Currie',
    'Australia/Darwin',
    'Australia/Eucla',
    'Australia/Hobart',
    'Australia/LHI',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/NSW',
    'Australia/North',
    'Australia/Perth',
    'Australia/Queensland',
    'Australia/South',
    'Australia/Sydney',
    'Australia/Tasmania',
    'Australia/Victoria',
    'Australia/West',
    'Australia/Yancowinna',
    'Brazil/Acre',
    'Brazil/DeNoronha',
    'Brazil/East',
    'Brazil/West',
    'Canada/Atlantic',
    'Canada/Central',
    'Canada/East-Saskatchewan',
    'Canada/Eastern',
    'Canada/Mountain',
    'Canada/Newfoundland',
    'Canada/Pacific',
    'Canada/Saskatchewan',
    'Canada/Yukon',
    'Chile/Continental',
    'Chile/EasterIsland',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Athens',
    'Europe/Belfast',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Bratislava',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Busingen',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Guernsey',
    'Europe/Helsinki',
    'Europe/Isle_of_Man',
    'Europe/Istanbul',
    'Europe/Jersey',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Lisbon',
    'Europe/Ljubljana',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Mariehamn',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Nicosia',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Podgorica',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/San_Marino',
    'Europe/Sarajevo',
    'Europe/Simferopol',
    'Europe/Skopje',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Tiraspol',
    'Europe/Uzhgorod',
    'Europe/Vaduz',
    'Europe/Vatican',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zagreb',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'Indian/Antananarivo',
    'Indian/Chagos',
    'Indian/Christmas',
    'Indian/Cocos',
    'Indian/Comoro',
    'Indian/Kerguelen',
    'Indian/Mahe',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Mayotte',
    'Indian/Reunion',
    'Mexico/BajaNorte',
    'Mexico/BajaSur',
    'Mexico/General',
    'Pacific/Apia',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'Pacific/Chuuk',
    'Pacific/Easter',
    'Pacific/Efate',
    'Pacific/Enderbury',
    'Pacific/Fakaofo',
    'Pacific/Fiji',
    'Pacific/Funafuti',
    'Pacific/Galapagos',
    'Pacific/Gambier',
    'Pacific/Guadalcanal',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Johnston',
    'Pacific/Kiritimati',
    'Pacific/Kosrae',
    'Pacific/Kwajalein',
    'Pacific/Majuro',
    'Pacific/Marquesas',
    'Pacific/Midway',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Palau',
    'Pacific/Pitcairn',
    'Pacific/Pohnpei',
    'Pacific/Ponape',
    'Pacific/Port_Moresby',
    'Pacific/Rarotonga',
    'Pacific/Saipan',
    'Pacific/Samoa',
    'Pacific/Tahiti',
    'Pacific/Tarawa',
    'Pacific/Tongatapu',
    'Pacific/Truk',
    'Pacific/Wake',
    'Pacific/Wallis',
    'Pacific/Yap',
  ],
  AF: ['Asia/Kabul'],
  AX: ['Europe/Mariehamn'],
  AL: ['Europe/Tirane'],
  DZ: ['Africa/Algiers'],
  AS: ['Pacific/Pago_Pago'],
  AD: ['Europe/Andorra'],
  AO: ['Africa/Luanda'],
  AI: ['America/Anguilla'],
  AQ: [
    'Antarctica/McMurdo',
    'Antarctica/South_Pole',
    'Antarctica/Rothera',
    'Antarctica/Palmer',
    'Antarctica/Mawson',
    'Antarctica/Davis',
    'Antarctica/Casey',
    'Antarctica/Vostok',
    'Antarctica/DumontDUrville',
    'Antarctica/Syowa',
  ],
  AG: ['America/Antigua'],
  AR: [
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
  ],
  AM: ['Asia/Yerevan'],
  AW: ['America/Aruba'],
  AU: [
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
  ],
  AT: ['Europe/Vienna'],
  AZ: ['Asia/Baku'],
  BS: ['America/Nassau'],
  BH: ['Asia/Bahrain'],
  BD: ['Asia/Dhaka'],
  BB: ['America/Barbados'],
  BY: ['Europe/Minsk'],
  BE: ['Europe/Brussels'],
  BZ: ['America/Belize'],
  BJ: ['Africa/Porto-Novo'],
  BM: ['Atlantic/Bermuda'],
  BT: ['Asia/Thimphu'],
  BO: ['America/La_Paz'],
  BA: ['Europe/Sarajevo'],
  BW: ['Africa/Gaborone'],
  BV: [null],
  BR: [
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
  ],
  IO: ['Indian/Chagos'],
  BN: ['Asia/Brunei'],
  BG: ['Europe/Sofia'],
  BF: ['Africa/Ouagadougou'],
  BI: ['Africa/Bujumbura'],
  KH: ['Asia/Phnom_Penh'],
  CM: ['Africa/Douala'],
  CA: [
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Montreal',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Resolute',
    'America/Atikokan',
    'America/Rankin_Inlet',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
  ],
  CV: ['Atlantic/Cape_Verde'],
  KY: ['America/Cayman'],
  CF: ['Africa/Bangui'],
  TD: ['Africa/Ndjamena'],
  CL: ['America/Santiago', 'Pacific/Easter'],
  CN: [
    'Asia/Shanghai',
    'Asia/Harbin',
    'Asia/Chongqing',
    'Asia/Urumqi',
    'Asia/Kashgar',
  ],
  CX: ['Indian/Christmas'],
  CC: ['Indian/Cocos'],
  CO: ['America/Bogota'],
  KM: ['Indian/Comoro'],
  CG: ['Africa/Brazzaville'],
  CD: ['Africa/Kinshasa', 'Africa/Lubumbashi'],
  CK: ['Pacific/Rarotonga'],
  CR: ['America/Costa_Rica'],
  CI: ['Africa/Abidjan'],
  HR: ['Europe/Zagreb'],
  CU: ['America/Havana'],
  CY: ['Asia/Nicosia'],
  CZ: ['Europe/Prague'],
  DK: ['Europe/Copenhagen'],
  DJ: ['Africa/Djibouti'],
  DM: ['America/Dominica'],
  DO: ['America/Santo_Domingo'],
  EC: ['America/Guayaquil', 'Pacific/Galapagos'],
  EG: ['Africa/Cairo'],
  SV: ['America/El_Salvador'],
  GQ: ['Africa/Malabo'],
  ER: ['Africa/Asmara'],
  EE: ['Europe/Tallinn'],
  ET: ['Africa/Addis_Ababa'],
  FK: ['Atlantic/Stanley'],
  FO: ['Atlantic/Faroe'],
  FJ: ['Pacific/Fiji'],
  FI: ['Europe/Helsinki'],
  FR: ['Europe/Paris'],
  GF: ['America/Cayenne'],
  PF: ['Pacific/Tahiti', 'Pacific/Marquesas', 'Pacific/Gambier'],
  TF: ['Indian/Kerguelen'],
  GA: ['Africa/Libreville'],
  GM: ['Africa/Banjul'],
  GE: ['Asia/Tbilisi'],
  DE: ['Europe/Berlin', 'Europe/Busingen'],
  GH: ['Africa/Accra'],
  GI: ['Europe/Gibraltar'],
  GR: ['Europe/Athens'],
  GL: [
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
  ],
  GD: ['America/Grenada'],
  GP: ['America/Guadeloupe'],
  GU: ['Pacific/Guam'],
  GT: ['America/Guatemala'],
  GG: ['Europe/Guernsey'],
  GN: ['Africa/Conakry'],
  GW: ['Africa/Bissau'],
  GY: ['America/Guyana'],
  HT: ['America/Port-au-Prince'],
  HM: [null],
  VA: ['Europe/Vatican'],
  HN: ['America/Tegucigalpa'],
  HK: ['Asia/Hong_Kong'],
  HU: ['Europe/Budapest'],
  IS: ['Atlantic/Reykjavik'],
  IN: ['Asia/Kolkata'],
  ID: ['Asia/Jakarta', 'Asia/Pontianak', 'Asia/Makassar', 'Asia/Jayapura'],
  IR: ['Asia/Tehran'],
  IQ: ['Asia/Baghdad'],
  IE: ['Europe/Dublin'],
  IM: ['Europe/Isle_of_Man'],
  IL: ['Asia/Jerusalem'],
  IT: ['Europe/Rome'],
  JM: ['America/Jamaica'],
  JP: ['Asia/Tokyo'],
  JE: ['Europe/Jersey'],
  JO: ['Asia/Amman'],
  KZ: [
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Oral',
  ],
  KE: ['Africa/Nairobi'],
  KI: ['Pacific/Tarawa', 'Pacific/Enderbury', 'Pacific/Kiritimati'],
  KP: ['Asia/Pyongyang'],
  KR: ['Asia/Seoul'],
  KW: ['Asia/Kuwait'],
  KG: ['Asia/Bishkek'],
  LA: ['Asia/Vientiane'],
  LV: ['Europe/Riga'],
  LB: ['Asia/Beirut'],
  LS: ['Africa/Maseru'],
  LR: ['Africa/Monrovia'],
  LY: ['Africa/Tripoli'],
  LI: ['Europe/Vaduz'],
  LT: ['Europe/Vilnius'],
  LU: ['Europe/Luxembourg'],
  MO: ['Asia/Macau'],
  MK: ['Europe/Skopje'],
  MG: ['Indian/Antananarivo'],
  MW: ['Africa/Blantyre'],
  MY: ['Asia/Kuala_Lumpur', 'Asia/Kuching'],
  MV: ['Indian/Maldives'],
  ML: ['Africa/Bamako'],
  MT: ['Europe/Malta'],
  MH: ['Pacific/Majuro', 'Pacific/Kwajalein'],
  MQ: ['America/Martinique'],
  MR: ['Africa/Nouakchott'],
  MU: ['Indian/Mauritius'],
  YT: ['Indian/Mayotte'],
  MX: [
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Santa_Isabel',
    'America/Bahia_Banderas',
  ],
  FM: ['Pacific/Chuuk', 'Pacific/Pohnpei', 'Pacific/Kosrae'],
  MD: ['Europe/Chisinau'],
  MC: ['Europe/Monaco'],
  MN: ['Asia/Ulaanbaatar', 'Asia/Hovd', 'Asia/Choibalsan'],
  ME: ['Europe/Podgorica'],
  MS: ['America/Montserrat'],
  MA: ['Africa/Casablanca'],
  MZ: ['Africa/Maputo'],
  MM: ['Asia/Rangoon'],
  NA: ['Africa/Windhoek'],
  NR: ['Pacific/Nauru'],
  NP: ['Asia/Kathmandu'],
  NL: ['Europe/Amsterdam'],
  AN: [null],
  NC: ['Pacific/Noumea'],
  NZ: ['Pacific/Auckland', 'Pacific/Chatham'],
  NI: ['America/Managua'],
  NE: ['Africa/Niamey'],
  NG: ['Africa/Lagos'],
  NU: ['Pacific/Niue'],
  NF: ['Pacific/Norfolk'],
  MP: ['Pacific/Saipan'],
  NO: ['Europe/Oslo'],
  OM: ['Asia/Muscat'],
  PK: ['Asia/Karachi'],
  PW: ['Pacific/Palau'],
  PS: ['Asia/Gaza', 'Asia/Hebron'],
  PA: ['America/Panama'],
  PG: ['Pacific/Port_Moresby'],
  PY: ['America/Asuncion'],
  PE: ['America/Lima'],
  PH: ['Asia/Manila'],
  PN: ['Pacific/Pitcairn'],
  PL: ['Europe/Warsaw'],
  PT: ['Europe/Lisbon', 'Atlantic/Madeira', 'Atlantic/Azores'],
  PR: ['America/Puerto_Rico'],
  QA: ['Asia/Qatar'],
  RE: ['Indian/Reunion'],
  RO: ['Europe/Bucharest'],
  RU: [
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Volgograd',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Sakhalin',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Kamchatka',
    'Asia/Anadyr',
  ],
  RW: ['Africa/Kigali'],
  BL: ['America/St_Barthelemy'],
  SH: ['Atlantic/St_Helena'],
  KN: ['America/St_Kitts'],
  LC: ['America/St_Lucia'],
  MF: ['America/Marigot'],
  PM: ['America/Miquelon'],
  VC: ['America/St_Vincent'],
  WS: ['Pacific/Apia'],
  SM: ['Europe/San_Marino'],
  ST: ['Africa/Sao_Tome'],
  SA: ['Asia/Riyadh'],
  SN: ['Africa/Dakar'],
  RS: ['Europe/Belgrade'],
  SC: ['Indian/Mahe'],
  SL: ['Africa/Freetown'],
  SG: ['Asia/Singapore'],
  SK: ['Europe/Bratislava'],
  SI: ['Europe/Ljubljana'],
  SB: ['Pacific/Guadalcanal'],
  SO: ['Africa/Mogadishu'],
  ZA: ['Africa/Johannesburg'],
  GS: ['Atlantic/South_Georgia'],
  ES: ['Europe/Madrid', 'Africa/Ceuta', 'Atlantic/Canary'],
  LK: ['Asia/Colombo'],
  SD: ['Africa/Khartoum'],
  SR: ['America/Paramaribo'],
  SJ: ['Arctic/Longyearbyen'],
  SZ: ['Africa/Mbabane'],
  SE: ['Europe/Stockholm'],
  CH: ['Europe/Zurich'],
  SY: ['Asia/Damascus'],
  TW: ['Asia/Taipei'],
  TJ: ['Asia/Dushanbe'],
  TZ: ['Africa/Dar_es_Salaam'],
  TH: ['Asia/Bangkok'],
  TL: ['Asia/Dili'],
  TG: ['Africa/Lome'],
  TK: ['Pacific/Fakaofo'],
  TO: ['Pacific/Tongatapu'],
  TT: ['America/Port_of_Spain'],
  TN: ['Africa/Tunis'],
  TR: ['Europe/Istanbul'],
  TM: ['Asia/Ashgabat'],
  TC: ['America/Grand_Turk'],
  TV: ['Pacific/Funafuti'],
  UG: ['Africa/Kampala'],
  UA: [
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Europe/Simferopol',
  ],
  AE: ['Asia/Dubai'],
  GB: ['Europe/London'],
  US: [
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Shiprock',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'America/Metlakatla',
    'Pacific/Honolulu',
  ],
  UM: ['Pacific/Johnston', 'Pacific/Midway', 'Pacific/Wake'],
  UY: ['America/Montevideo'],
  UZ: ['Asia/Samarkand', 'Asia/Tashkent'],
  VU: ['Pacific/Efate'],
  VE: ['America/Caracas'],
  VN: ['Asia/Ho_Chi_Minh'],
  VG: ['America/Tortola'],
  VI: ['America/St_Thomas'],
  WF: ['Pacific/Wallis'],
  EH: ['Africa/El_Aaiun'],
  YE: ['Asia/Aden'],
  ZM: ['Africa/Lusaka'],
  ZW: ['Africa/Harare'],
};

export function getBrowserCountryAlpha2FromTimezone() {
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let countryKey = null;
  Object.keys(TIMEZONE_TO_CODE).forEach((key) => {
    const timezonesInThisKey = TIMEZONE_TO_CODE[key];
    if (timezonesInThisKey?.includes(browserTimeZone)) {
      countryKey = key;
    }
  });
  return countryKey;
}

export function getDefaultPhoneCodeOption() {
  const browserCountry = getBrowserCountryAlpha2FromTimezone();
  if (browserCountry) {
    const foundPhoneCode = PHONE_CODES.find(
      (phoneCode) => phoneCode.code === browserCountry,
    );
    if (foundPhoneCode) {
      return foundPhoneCode;
    }
  }
  return PHONE_CODES[0];
}
