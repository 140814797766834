export function loadLinkedInInsightScript(linkedInPartnerId) {
  if (window && document?.documentElement?.firstChild) {
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(linkedInPartnerId);
    (function(l) {
    if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
    window.lintrk.q=[];}
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript";b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);
    })(window.lintrk);

    const noScriptElement = document.createElement('noscript');
    const imgElement = document.createElement('img');
    imgElement.height = 1;
    imgElement.width = 1;
    imgElement.style.display = 'none';
    imgElement.alt = "";
    imgElement.src = `https://px.ads.linkedin.com/collect/?pid=${linkedInPartnerId}&fmt=gif`;
    noScriptElement.appendChild(imgElement);
    document.documentElement.firstChild.appendChild(noScriptElement);
  }
}

export function sendLinkedInInsightConversionEvent(linkedInConversionId, email) {
  if(window.lintrk){
    window.lintrk('track', { conversion_id: linkedInConversionId });
  }
}