import { APIBaseService } from './base.api';
import { APIClientsService } from './clients';
import { APIFormsService } from './forms.api';
import { ApiImagesService } from './images.api';
import { APIStatsService } from './stats.api';

class APIService extends APIBaseService {
  static formsService = APIFormsService;
  static imagesService = ApiImagesService;
  static statsService = APIStatsService;
  static clientsService = APIClientsService;
}

export default APIService;
