import React from 'react';
import { TapformLogo } from '../../assets/images/icons';
import './index.scss';

export interface WatermarkProps {
  className?: string;
}

function Watermark({ className = '' }: WatermarkProps) {
  return (
    <div
      className={`watermark-component-container ${className}`}
      onClick={() => window.open('https://tapform.io', '_blank')}
    >
      <div className="watermark-component-label">Powered by</div>
      <TapformLogo type="white" className="watermark-component-logo" />
    </div>
  );
}

export default Watermark;
