export enum ActionTypes {
  LOAD_FORM = 'LOAD_FORM',
  LOAD_RESPONSE = 'LOAD_RESPONSE',
  DELETE_RESPONSE = 'DELETE_RESPONSE',
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  FORM_NEXT_STEP = 'FORM_NEXT_STEP',
  FORM_PREVIOUS_STEP = 'FORM_PREVIOUS_STEP',
  RESET_ANSWERS = 'RESET_ANSWERS',
  RECORD_ANSWER = 'RECORD_ANSWER',
  RECORD_LEAD = 'RECORD_LEAD',
  UPDATE_ANIMATING_COMPONENTS = 'UPDATE_ANIMATING_COMPONENTS',
  REMOVE_ANIMATING_COMPONENTS = 'REMOVE_ANIMATING_COMPONENTS',
  GIVE_CONSENT = 'GIVE_CONSENT',
  REMOVE_CONSENT = 'REMOVE_CONSENT',
  SET_DEMO_SUBMISSION = 'SET_DEMO_SUBMISSION',
}
