import React from 'react';
import './index.scss';

export function BackIcon(props: any) {
  return (
    <svg
      height="33"
      width="33"
      viewBox="0 0 33 33"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <rect height="33" width="33" rx="1" />
        <path
          d="M24.567 16.398a3.784 3.784 0 0 0-3.363-1.993h-10.2l3.482-4.177L13.013 9l-4.792 5.75a.962.962 0 0 0 0 1.228l4.792 5.75 1.473-1.228-3.482-4.178h10.2c.711 0 1.34.373 1.681.996.342.624.317 1.355-.012 1.877l-2.849 3.619L21.531 24l2.903-3.695a3.782 3.782 0 0 0 .133-3.907z"
          fill="#041524"
        />
      </g>
    </svg>
  );
}

export function PhoneIcon(props: any) {
  return (
    <svg
      height="22"
      width="22"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h22v22H0z" />
        <path
          d="M3.668 1.833h4.36l1.7 6.927-3.86 2.02.147.242a15.666 15.666 0 0 0 4.938 4.937l.24.148 2.023-3.86 6.926 1.701v4.359c0 1.04-.867 1.89-1.912 1.831-8.828-.497-15.896-7.566-16.394-16.393-.057-.999.72-1.836 1.698-1.906l.135-.006zM14.172 14.37l-1.355 2.588.227.101a15.48 15.48 0 0 0 4.979 1.229l.285.018v-2.92l-4.135-1.016h-.001zM7.605 7.802 6.59 3.667H3.669l.018.286c.135 1.756.559 3.432 1.228 4.977l.101.228 2.589-1.356z"
          fill="#0D3051"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export interface TapformLogoProps {
  type?: 'blue' | 'white';
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

export function TapformLogo({
  type,
  color = '#B3B8C3',
  className,
  onClick,
  style,
}: TapformLogoProps) {
  return (
    <svg
      width="111"
      height="25"
      viewBox="0 0 111 25"
      xmlns="http://www.w3.org/2000/svg"
      className={`image-tapform-logo ${type || ''} ${className || ''}`}
      onClick={onClick}
      style={style}
    >
      <path
        d="M8.389 20.709c1.162 0 2.16-.25 3.599-1.025l-1.052-3.045c-.72.388-1.661.664-2.298.664-1.218 0-2.021-.72-2.021-2.38V9.467h4.513v-2.99H6.617v-4.18H2.962v4.18H0v2.99h2.962V15.2c0 3.793 2.16 5.51 5.427 5.51zm11.24 0c1.883 0 3.405-.554 4.568-1.495v1.08h3.655V6.477h-3.655v1.108c-1.163-.97-2.685-1.523-4.568-1.523-3.516 0-6.783 2.99-6.783 7.337 0 4.346 3.267 7.309 6.783 7.309zm.637-3.184c-2.215 0-3.876-1.578-3.876-4.125 0-2.547 1.66-4.153 3.876-4.153 1.965 0 3.931 1.606 3.931 4.153 0 2.547-1.966 4.125-3.931 4.125zM35.382 25v-5.786c1.163.941 2.686 1.495 4.568 1.495 3.516 0 6.783-2.963 6.783-7.31 0-4.346-3.267-7.336-6.783-7.336-1.882 0-3.405.554-4.568 1.523V6.478h-3.654V25h3.654zm3.931-7.475c-1.965 0-3.93-1.578-3.93-4.125 0-2.547 1.965-4.153 3.93-4.153 2.215 0 3.876 1.606 3.876 4.153 0 2.547-1.66 4.125-3.876 4.125zm14.563 2.768V9.468h4.18v-2.99h-4.18V5.233c0-1.302.858-1.994 2.049-1.994.387 0 .886.083 1.384.25l.83-2.963A5.711 5.711 0 0 0 55.677 0c-3.24 0-5.455 1.993-5.455 5.233v1.245h-2.104v2.99h2.104v10.825h3.655zm12.265.36c4.042 0 7.447-2.907 7.447-7.253 0-4.347-3.405-7.254-7.447-7.254-4.125 0-7.53 2.907-7.53 7.254 0 4.346 3.405 7.253 7.53 7.253zm0-3.128c-1.994 0-3.987-1.55-3.987-4.125s1.993-4.125 3.987-4.125c1.91 0 3.903 1.55 3.903 4.125s-1.993 4.125-3.903 4.125zm13.87 2.768v-4.457c0-3.848 1.55-5.925 4.928-5.925h.803l.194-3.516c-.332-.11-.61-.11-.97-.11-2.242 0-3.848 1.107-4.955 2.713v-2.52h-3.654v13.815h3.654zm11.877 0V13.65c0-2.907 1.218-4.236 3.156-4.236 1.523 0 2.713 1.135 2.713 2.99v7.89h3.655V13.65c0-2.907 1.273-4.236 3.211-4.236 1.523 0 2.714 1.135 2.714 2.99v7.89h3.626v-8.637c0-3.378-2.491-5.593-5.37-5.593-2.215 0-3.904.858-5.04 2.27-.996-1.467-2.685-2.27-4.54-2.27-1.8 0-3.156.582-4.125 1.55V6.479h-3.654v13.815h3.654z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
}

export function CheckmarkIcon() {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-4-6h24v24H-4z" />
        <path
          stroke="#FFF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m2 7 4.397 4 8.684-9"
        />
      </g>
    </svg>
  );
}

export interface PhoneCodeOptionCheckmarkProps {
  className?: string;
}

export function PhoneCodeOptionCheckmark({
  className = '',
}: PhoneCodeOptionCheckmarkProps) {
  return (
    <svg
      height="13"
      width="17"
      viewBox="0 0 17 13"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-4-6h24v24H-4z" />
        <path
          d="m2 7 4.397 4 8.684-9"
          stroke="#1A9ED9"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
}

export interface SearchIconProps {
  className?: string;
}

export function SearchIcon({ className = '' }: SearchIconProps) {
  return (
    <svg
      height="16"
      width="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="m15.725 14.486-2.87-2.85c2.24-2.87 2.005-6.958-.57-9.533A7.15 7.15 0 0 0 7.195 0a7.15 7.15 0 0 0-5.092 2.103A7.15 7.15 0 0 0 0 7.194a7.15 7.15 0 0 0 2.103 5.091 7.15 7.15 0 0 0 5.091 2.103 7.11 7.11 0 0 0 4.423-1.533l2.85 2.89a.86.86 0 0 0 .629.255.947.947 0 0 0 .629-.256.878.878 0 0 0 0-1.258zm-3.106-7.292c0 1.455-.57 2.81-1.592 3.833a5.416 5.416 0 0 1-3.833 1.592c-1.435 0-2.81-.57-3.833-1.592A5.416 5.416 0 0 1 1.77 7.194c0-1.454.57-2.81 1.592-3.833A5.416 5.416 0 0 1 7.194 1.77c1.455 0 2.811.57 3.833 1.592a5.382 5.382 0 0 1 1.592 3.833z"
        fill="#B6C1CB"
        fillRule="nonzero"
      />
    </svg>
  );
}

export interface DropdownIconProps {
  className?: string;
}

export function DropdownIcon({ className }: DropdownIconProps) {
  return (
    <svg
      height="20"
      width="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="m6 8 4 4 4-4"
          stroke="#34393E"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
