/* eslint-disable no-unused-expressions */
export function loadFBPixelScript(fbPixelId) {
  if (window && document?.documentElement?.firstChild) {
    const newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    newScript.setAttribute('async', 'true');
    newScript.setAttribute(
      'src',
      `https://connect.facebook.net/en_US/fbevents.js`,
    );
    document.documentElement.firstChild.appendChild(newScript);
    newScript.onload = () => {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
      );
      if (window.fbq) {
        window.fbq('init', fbPixelId);
      }
    };
  }
}

export function sendFBPixelLeadEvent(fbPixelId, lead) {
  if (window.fbq) {
    const hashData = {};
    if (lead?.email) {
      hashData.em = lead.email;
    }
    if (lead?.firstName) {
      hashData.fn = lead.firstName;
    }
    if (lead?.lastName) {
      hashData.ln = lead.lastName;
    }
    if (lead?.phoneNumber) {
      hashData.ph = lead.phoneNumber;
    }
    window.fbq('init', fbPixelId, hashData);
    window.fbq('track', 'Lead');
  }
}
