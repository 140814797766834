export enum ParentAppMessageCommand {
  RESPONSE = '/response',
  OPEN = '/open',
  LOAD_FORM = '/load-form',
  GIVE_CONSENT = '/give-consent',
  REMOVE_CONSENT = '/remove-consent',
}

export enum WidgetAppMessageCommand {
  INIT = '/init',
  EXITED = '/exited',
  CLOSE = '/close',
  SEND = '/send-request',
}

export interface ParentAppMessage {
  key?: string;
  command?: ParentAppMessageCommand;
  data?: object;
}

export interface WidgetAppMessage {
  key: 'tapform-app';
  command: WidgetAppMessageCommand;
  data: object;
}
