import React from 'react';
import './index.scss';

export interface ButtonProps {
  className?: string;
  text: string;
  onClick?: () => void;
  disabled: boolean;
  style?: React.CSSProperties;
}

function Button({ className, text, onClick, disabled, style }: ButtonProps) {
  return (
    <button
      className={`button-component ${className}`}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {text}
    </button>
  );
}

export default Button;
