import useEventListener from './use-event-listener';

const useClickOutside = (ref: any, callback: any) => {
  useEventListener(
    'click',
    (e: any) => {
      if (ref.current == null || ref.current.contains(e.target)) return;
      callback(e);
    },
    document as any,
  );
};

export default useClickOutside;
