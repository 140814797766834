import './index.scss';
import React, { FocusEventHandler } from 'react';
import { CheckmarkIcon } from '../../assets/images/icons';
import { motion } from 'framer-motion';
import ParentAppService from '../../services/parentApp';
import { useTextareaFocused } from '../../hooks/use-textarea-focused';

export type InputPropsError = string[];

export interface TextareaProps {
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  name?: string;
  For?: string;
  label?: string;
  errors?: InputPropsError;
  valid?: boolean;
  onChange?: (value: string) => void;
  style?: React.CSSProperties;
  motionDelay?: number;
  motionDuration?: number;
  autoComplete?: string;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  maxLength?: number;
}

function Textarea({
  defaultValue = '',
  placeholder,
  label,
  className = '',
  name = ParentAppService.generateUUID(),
  errors = [],
  valid = false,
  onChange,
  style,
  motionDelay = 0,
  motionDuration = 0.45,
  autoComplete,
  onBlur,
  maxLength = 100,
}: TextareaProps) {
  const isValid = valid && !errors.length;

  const inputRef =
    React.useRef() as React.MutableRefObject<HTMLTextAreaElement>;
  const focused = useTextareaFocused(inputRef);

  const [value, setValue] = React.useState(defaultValue);

  return (
    <motion.div
      className={`textarea-component-container ${className} ${
        focused ? 'focused' : ''
      }`}
      style={style}
      initial={{ opacity: 0, translateY: '30px' }}
      animate={{ opacity: 1, translateY: '0%' }}
      exit={{ opacity: 0, transition: { delay: 0 } }}
      transition={{
        duration: motionDuration,
        delay: motionDelay,
        ease: 'anticipate',
      }}
    >
      {label ? (
        <label
          className={`textarea-component-label ${focused ? 'focused' : ''} ${
            value.length ? 'has-value' : ''
          }`}
          htmlFor={name}
        >
          {label}
        </label>
      ) : null}
      <textarea
        onBlur={onBlur}
        ref={inputRef}
        data-toggle="tooltip"
        data-placement="top"
        title="This is the text of the tooltip"
        value={value}
        placeholder={placeholder}
        onChange={(event) => {
          setValue(event.target.value);
          if (onChange) {
            onChange(event.target.value);
          }
        }}
        autoComplete={autoComplete}
        className={`textarea-component-textarea ${
          errors.length ? 'error' : ''
        }`}
        name={name}
        id={name}
        maxLength={maxLength}
      />
      <span
        className={`textarea-component-valid ${
          isValid ? 'active' : 'inactive'
        }`}
      >
        <CheckmarkIcon />
      </span>
    </motion.div>
  );
}

export default Textarea;
