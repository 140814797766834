import React from 'react';
import './index.scss';

export interface SpinnerProps {
  className?: string;
}

function Spinner({ className = '' }: SpinnerProps) {
  return (
    <div className={`spinner-component ${className}`}>
      <div className="pinner-component-bounce1"></div>
      <div className="pinner-component-bounce2"></div>
      <div className="pinner-component-bounce3"></div>
    </div>
  );
}

export default Spinner;
