export enum StatsType {
  OPEN_FROM_POPUP = 'open-from-popup',
  OPEN_FROM_WIDGET = 'open-from-widget',
  OPEN_FROM_SDK = 'open-from-sdk',
  OPEN_FROM_LINK = 'open-from-link',
  SUBMIT_DROPOUT = 'form-dropout',
  START_PHONE_CALL_HEADER = 'start-phone-call-header',
  START_PHONE_CALL_SUBMIT = 'start-phone-call-submit',
  FORM_SUBMITTED = 'form-submitted',
  FORM_INTEGRATED_ON_WEBSITE = 'form-integrated-on-website',
}

export interface StatsReportRequest {
  type: StatsType;
  clientKey: string;
  leadId?: number;
  stepId?: number;
}

export interface StatsReportResponse {
  data: {
    success: boolean;
  };
}
