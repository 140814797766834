import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkForTapformDemoSubmissionParam,
  collectUTMParams,
  hexToRgb,
} from '../../helpers';
import APIService from '../../services/api/index.api';
import { FormsSubmitClientResponse } from '../../services/api/types/forms.responses';
import { StatsType } from '../../services/api/types/stats.response.d';
import ParentAppService from '../../services/parentApp';
import {
  getFormDemoSubmission,
  getFormStateSelector,
  getResponseStateSelector,
  getUIStateSelector,
} from '../../store/selectors';
import Header from '../Header';
import StepWindow from '../StepWindow';
import Watermark from '../Watermark';
import WizardStepBar from '../WizardStepBar';
import './index.scss';
import * as Sentry from '@sentry/react';
import { actionSetDemoSubmission } from '../../store/actions/form.actions';
import { loadCalendly } from '../../services/integrations/calendly';
import { loadGA4Script } from '../../services/integrations/ga4';
import { loadGoogleAdsScript } from '../../services/integrations/google-ads';
import { loadFBPixelScript } from '../../services/integrations/facebook-pixel';
import { loadWhatConvertsScript } from '../../services/integrations/what-converts';
import { loadTikTokPixelScript } from '../../services/integrations/tiktok-pixel';
import { loadLinkedInInsightScript } from '../../services/integrations/linkedin-insight';
import { loadGoogleTagManagerScript } from '../../services/integrations/google-tag-manager';

if (
  process.env.NODE_ENV !== 'development' &&
  process.env.REACT_APP_SENTRY_DNS
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
  });
}

declare global {
  interface Window {
    Calendly: any;
    clarity: any;
    gtag: any;
  }
}

function App() {
  const dispatch = useDispatch();
  ParentAppService.initListener();

  const { brandColor, loaded, clientKey, lastStepId, settings, consent } =
    useSelector(getFormStateSelector);
  const isDemoSubmission = useSelector(getFormDemoSubmission);
  const { isOpen } = useSelector(getUIStateSelector);
  const response = useSelector(getResponseStateSelector('submit-response'));
  const _isFromLink = ParentAppService.checkIfFormLink();
  const _isFromWebsite = ParentAppService.checkIfInsideWebsite();
  const loadClientIntegrations = _isFromLink && !_isFromWebsite;

  React.useEffect(() => {
    ParentAppService.sendInitMessage({});

    // Check if the form is in demo submission mode
    const demoSubmission = checkForTapformDemoSubmissionParam();
    dispatch(
      actionSetDemoSubmission({ demoSubmission: demoSubmission ?? false }),
    );
  }, []);

  React.useEffect(() => {
    if (brandColor) {
      const rgbBrandColor = hexToRgb(brandColor);
      document.getElementById('tapform-dynamic-styles')?.remove();
      const styleEl = document.createElement('style');
      styleEl.id = 'tapform-dynamic-styles';
      styleEl.innerText = `html {--brand-color: #${brandColor}; --brand-color-r: ${rgbBrandColor?.[0]}; --brand-color-g: ${rgbBrandColor?.[1]}; --brand-color-b: ${rgbBrandColor?.[2]};}`;
      document.head.appendChild(styleEl);
    }
  }, [brandColor]);

  React.useEffect(() => {
    const responseData =
      response && (response.response as FormsSubmitClientResponse);
    if (!isOpen && lastStepId && clientKey && !isDemoSubmission) {
      APIService.statsService.report({
        type: StatsType.SUBMIT_DROPOUT,
        stepId: lastStepId,
        clientKey,
        leadId: responseData ? responseData.data.leadId : undefined,
      });
    }
  }, [isOpen]);

  React.useEffect(() => {
    if ((window as any).clarity && clientKey) {
      try {
        (async () => {
          const res = await fetch('https://ipapi.co/in_eu');
          const data = await res.text();
          if (res.status === 200 && data === 'False') {
            const res2 = await fetch('https://ipapi.co/country');
            const data2 = await res2.text();
            if (res2.status === 200 && data2) {
              APIService.clientsService.reportClientCountry({
                clientKey,
                country: data2,
              });
            }
          }
        })();
      } catch (e) {}

      // Collect UTM
      const isFromLink = ParentAppService.checkIfFormLink();
      if (isFromLink && settings?.collectUTM) {
        const utmParams = collectUTMParams();
        if (utmParams.length) {
          APIService.clientsService.reportClientUTM({
            clientKey,
            data: utmParams,
          });
        }
      }
    }
  }, [clientKey]);

  // Load calendly
  React.useEffect(() => {
    if (settings?.calendlyUrl?.length && document.head) {
      loadCalendly();
    }
    if (settings && loadClientIntegrations) {
      if (settings?.tikTokPixelId) {
        loadTikTokPixelScript(settings.tikTokPixelId);
      }
      if (settings?.linkedInPartnerId) {
        loadLinkedInInsightScript(settings.linkedInPartnerId);
      }
      if (settings?.googleTagManagerId) {
        loadGoogleTagManagerScript(settings.googleTagManagerId);
      }
      if (settings?.ga4ID) {
        loadGA4Script(settings.ga4ID);
      }
      if (settings?.googleAdsTagId) {
        loadGoogleAdsScript(settings.googleAdsTagId);
      }
      if (settings?.facebookPixelId) {
        loadFBPixelScript(settings.facebookPixelId);
      }
      if (settings?.whatConvertsProfileId) {
        loadWhatConvertsScript(settings.whatConvertsProfileId);
      }
    }
  }, [settings]);

  React.useEffect(() => {
    if (consent) {
      if (window.clarity) {
        window.clarity('consent');
      }
      if (window.gtag) {
        window.gtag('consent', 'update', {
          ad_storage: 'granted',
          analytics_storage: 'granted',
          ad_user_data: 'granted',
          ad_personalization: 'granted',
        });
      }
    }
  }, [consent]);

  if (!loaded || !isOpen) {
    return null;
  }

  return (
    <>
      <WizardStepBar />
      <Header />
      <StepWindow />
      {window.visualViewport?.width &&
      window.visualViewport?.width <= 1024 ? null : (
        <div className="app-watermark">
          <Watermark />
        </div>
      )}
    </>
  );
}

export default App;
