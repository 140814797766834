import { createLogger } from 'redux-logger';
import rootReducer from './reducers/root.reducer';
import { configureStore } from '@reduxjs/toolkit';

const reduxLogger = createLogger({
  collapsed: true,
});

function createStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware:
      process.env.NODE_ENV === 'development' ? [reduxLogger] : undefined,
  });

  return { store };
}

export default createStore;
