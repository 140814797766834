export interface ClientsReportCountryBody {
  clientKey: string;
  country: string;
}

export interface ClientsReportUTMBody {
  clientKey: string;
  data: {
    parameter: string;
    value: string;
  }[];
}

export enum UTMParameters {
  // UTM Parameters
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_TERM = 'utm_term',
  UTM_CONTENT = 'utm_content',
  UTM_ID = 'utm_id',
  UTM_CREATIVE = 'utm_creative',

  // Additional Common Tracking Parameters
  REFERRER = 'referrer',
  CLICK_ID = 'click_id',
  TRACKING_ID = 'tracking_id',
  AFFILIATE_ID = 'affiliate_id',
  NETWORK_ID = 'network_id',
  AD_ID = 'ad_id',
  ADGROUP_ID = 'adgroup_id',
  KEYWORD = 'keyword',
  CREATIVE_ID = 'creative_id',
  DEVICE_TYPE = 'device_type',
  SOURCE_TYPE = 'source_type',
  CHANNEL = 'channel',
  CAMPAIGN_ID = 'campaign_id',

  // Social Media Specific Parameters
  FBCLID = 'fbclid', // Facebook Click Identifier
  GCLID = 'gclid', // Google Click Identifier
  MSCLKID = 'msclkid', // Microsoft Click Identifier
  TWCLID = 'twclid', // Twitter Click Identifier
  PCLID = 'pclid', // Pinterest Click Identifier

  // Email Marketing Parameters
  EMAIL_CAMPAIGN = 'email_campaign',
  EMAIL_USER = 'email_user',
  EMAIL_LINK = 'email_link',
}
