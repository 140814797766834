import { ReduxActionPayload } from './types/index.d';
import {
  ActionDeleteResponse,
  ActionLoadResponsePayload,
} from './types/responses.actions.d';
import { ActionTypes } from './types/type.actions.d';

export function actionLoadResponse(
  payload: ActionLoadResponsePayload,
): ReduxActionPayload {
  return {
    type: ActionTypes.LOAD_RESPONSE,
    payload,
  };
}

export function actionDeleteResponse(
  payload: ActionDeleteResponse,
): ReduxActionPayload {
  return {
    type: ActionTypes.DELETE_RESPONSE,
    payload,
  };
}
