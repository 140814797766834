export function loadCalendly() {
  const calendlyStyles = document.createElement('link');
  calendlyStyles.rel = 'stylesheet';
  calendlyStyles.href =
    'https://assets.calendly.com/assets/external/widget.css';
  const calendlyScript = document.createElement('script');
  calendlyScript.type = 'text/javascript';
  calendlyScript.src = 'https://assets.calendly.com/assets/external/widget.js';
  calendlyScript.onload = () => {
    console.log('Calendly loaded');
  };
  document.head.appendChild(calendlyStyles);
  document.head.appendChild(calendlyScript);
}
