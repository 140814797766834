export function loadGoogleTagManagerScript(googleTagManagerId) {
  if (window && document?.documentElement?.firstChild) {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    j.onload = () => {
      function loadCheck() {
        if (!tf_gtm_dataLayer && window.dataLayer) {
          tf_gtm_dataLayer = window.dataLayer;
        }
        else {
          setTimeout(() => {
            loadCheck();
          }, 500);
        }
      }
      loadCheck();
    }
    })(window,document,'script','dataLayer', googleTagManagerId);
    
    const noscriptElement = document.createElement('noscript');
    const iframeElement = document.createElement('iframe');
    iframeElement.src = `https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`;
    iframeElement.height = 0;
    iframeElement.width = 0;
    iframeElement.style.display = 'none';
    iframeElement.style.visibility = 'hidden';
    noscriptElement.appendChild(iframeElement);

    document.documentElement.firstChild.appendChild(noscriptElement);
  }
}

export function sendGoogleTagManagerConversionEvent(email, googleTagManagerConversionEvent) {
  if (window.dataLayer && googleTagManagerConversionEvent) {
    window.dataLayer.push({
      event: googleTagManagerConversionEvent,
      email,
    });
  }
}