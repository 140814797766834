import './index.scss';
import React, {
  HTMLInputTypeAttribute,
  FocusEventHandler,
  KeyboardEventHandler,
} from 'react';
import { CheckmarkIcon } from '../../assets/images/icons';
import { useInputFocused } from '../../hooks/use-input-focused';
import { motion } from 'framer-motion';
import ParentAppService from '../../services/parentApp';

export type InputPropsError = string[];

export interface InputProps {
  defaultValue?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  className?: string;
  name?: string;
  For?: string;
  label?: string;
  errors?: InputPropsError;
  valid?: boolean;
  onChange?: (value: string) => void;
  style?: React.CSSProperties;
  motionDelay?: number;
  motionDuration?: number;
  autoComplete?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  variant?: 'default' | 'large';
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  defaultFocused?: boolean;
  tooltip?: string;
}

function Input({
  defaultValue = '',
  placeholder,
  label,
  type,
  className = '',
  name = ParentAppService.generateUUID(),
  errors = [],
  valid = false,
  onChange,
  style,
  motionDelay = 0,
  motionDuration = 0.45,
  autoComplete,
  variant = 'default',
  onBlur,
  onKeyUp,
  defaultFocused,
  tooltip = '',
}: InputProps) {
  const isValid = valid && !errors.length;

  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const focused = useInputFocused(inputRef);

  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => {
    if (defaultFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <motion.div
      className={`input-component-container ${className} ${
        focused ? 'focused' : ''
      } ${variant}`}
      style={style}
      initial={{ opacity: 0, translateY: '30px' }}
      animate={{ opacity: 1, translateY: '0%' }}
      exit={{ opacity: 0, transition: { delay: 0 } }}
      transition={{
        duration: motionDuration,
        delay: motionDelay,
        ease: 'anticipate',
      }}
    >
      <input
        onBlur={onBlur}
        ref={inputRef}
        data-toggle="tooltip"
        data-placement="top"
        title={tooltip}
        value={value}
        placeholder={placeholder}
        type={type || 'text'}
        onChange={(event) => {
          setValue(event.target.value);
          if (onChange) {
            onChange(event.target.value);
          }
        }}
        autoComplete={autoComplete}
        className={`input-component-input ${
          errors.length ? 'error' : ''
        } ${variant}`}
        name={name}
        id={name}
        onKeyUp={onKeyUp}
      />
      {label ? (
        <label
          className={`input-component-label ${focused ? 'focused' : ''} ${
            value.length ? 'has-value' : ''
          } ${errors.length && focused ? 'error' : ''} ${variant}`}
          htmlFor={name}
        >
          {label}
        </label>
      ) : null}
      <span
        className={`input-component-valid ${
          isValid ? 'active' : 'inactive'
        } ${variant}`}
      >
        <CheckmarkIcon />
      </span>
    </motion.div>
  );
}

export default Input;
