import React from 'react';
import { useSelector } from 'react-redux';
import APIService from '../../services/api/index.api';
import { getFormStateSelector } from '../../store/selectors';
import './index.scss';

export interface LogoImageProps {
  className?: string;
  animate?: boolean;
}

function LogoImage({ className = '', animate = false }: LogoImageProps) {
  const { logoImageId } = useSelector(getFormStateSelector);

  const logoImageUrl = React.useMemo(() => {
    if (typeof logoImageId === 'number') {
      return APIService.imagesService.createImageUrl(logoImageId);
    }
    return null;
  }, [logoImageId]);

  if (!logoImageUrl) {
    return null;
  }

  return (
    <img
      className={`logo-image-component ${className} ${
        animate ? 'animate' : ''
      }`}
      src={logoImageUrl}
    />
  );
}

export default LogoImage;
